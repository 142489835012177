<template>
  <app-backdrop :is-visible="isVisible" @click="$emit('close-btn-click')">
    <transition name="horizontal-slide" appear>
      <div v-show="isVisible" class="navbar-menu scrollable">
        <div class="navbar-menu__head">
          <close-btn class="navbar-menu__close-btn" @click="$emit('close-btn-click')" />
        </div>
        <ul>
          <li v-for="item in items" :key="item.id" :class="{ dropdown: isDropdown(item) }">
            <dropdown-menu v-if="isDropdown(item)"
                           :item="item"
                           :default-opened="isDefaultOpened(item)"
                           @anchor-click="handleAnchorClick" />
            <a v-if="isAnchor(item)"
               :href="item.url"
               @click="handleAnchorClick({ event: $event, url: item.url })">{{ item.title }}</a>
          </li>
        </ul>
      </div>
    </transition>
  </app-backdrop>
</template>
<script>
import getScrollMixin from '@landing/common/mixins/bodyScrollMixin'
import { parseUrl } from '@landing/landing-mobile/utils'
import AppBackdrop from './AppBackdrop'
import CloseBtn from './CloseBtn'
import DropdownMenu from './DropdownMenu'

const bodyScrollMixin  = getScrollMixin('isVisible')

export default {
  name: 'NavbarMenu',
  components: {
    AppBackdrop,
    CloseBtn,
    DropdownMenu
  },
  mixins: [bodyScrollMixin],
  props: {
    isVisible: Boolean,
    items: Array
  },
  methods: {
    isDropdown(item) {
      return item.type === 'NavCategory'
    },
    isAnchor(item) {
      return ['NavStaticPage', 'Page'].includes(item.type)
    },
    isDefaultOpened(item) {
      const firstDropdownItem = this.items.find(this.isDropdown)
      return (item.id === firstDropdownItem.id)
    },
    handleAnchorClick({ event, url }) {

      const { host, path, pathname } = parseUrl(url)
      const isHistorySupported = window.history && history.pushState
      const sameOrigin = (host === location.host)
      const isSalesPage = /^\/[A-Za-z0-9-_]+$/.test(pathname)
      const isStaticPage = /^\/contents\/\w+$/.test(pathname)

      if (isHistorySupported && sameOrigin && (isSalesPage || isStaticPage)) {
        event.preventDefault()
        event.stopPropagation()
        this.$router.push(path)
        this.$emit('router-push')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-menu__head {
  display: flex;
  justify-content: flex-end;
}
.navbar-menu__close-btn {
  color: $white1;
  font-size: 18px;
}
.navbar-menu {
  height: 100%;
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 74px);
  font-size: .93em;
  color: $color-navbar-menu;
  background-color: $bg-navbar-menu;
  a {
    color: $color-navbar-menu;
  }
  > ul > li {
    padding: 14px;
    box-shadow: 0 1px rgba(255, 255, 255, .15) inset,
      0 -1px rgba(0, 0, 0, .1) inset;
  }
  > ul > li.dropdown {
    padding: 0;
  }
  ul li img {
    width: 100%;
  }
}
</style>
