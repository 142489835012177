<template>
  <vertical-menu :is-visible="isVisible" heading="選擇排序"
                 @backdrop-click="$emit('close')" @close="$emit('close')">
    <div class="sort-menu__content">
      <checkmark-btn v-for="row in methodRows"
                     :key="row.value"
                     :checked="row.value === selectedMethod"
                     class="sort-menu__btn"
                     @click="$emit('checkmark-btn-click', row.value)">
        {{ row.text }}
      </checkmark-btn>
    </div>
  </vertical-menu>
</template>

<script>
import CheckmarkBtn from './CheckmarkBtn'
import VerticalMenu from './VerticalMenu'

export default {
  name: 'SortMenu',
  components: {
    CheckmarkBtn,
    VerticalMenu
  },
  props: {
    isVisible: Boolean,
    selectedMethod: String,
    methodRows: Array
  }
}
</script>

<style lang="scss" scoped>
.sort-menu__content {
  padding: 0 14px 16px;
}
.sort-menu__btn {
  margin-right: 14px;
  margin-bottom: 14px;
}
</style>
