<template>
  <li class="cart-modal-list-item card" data-cy="cart-item">
    <div class="cart-modal-list-item__content">
      <cart-modal-list-item-image v-if="hasImageCol"
                                  class="cart-modal-list-item__image"
                                  :item="item" />
      <div class="cart-modal-list-item__info">
        <div v-if="item.type === 'product'" class="cart-modal-list-item__heading">
          {{ item.qty }} {{ item.unitName }}{{ heading }}
          <strong v-if="item.addtional">[加購]</strong>
          <strong v-else-if="item.isDiscountLevelItem" class="free-product-label">[贈品]</strong>
          <strong v-else-if="item.type === 'free_product'" class="free-product-label">[贈品]</strong>
        </div>
        <div v-else-if="item.type === 'promo'" class="cart-modal-list-item__heading">
          {{ heading }}
        </div>
        <div v-else-if="item.isDiscountLevelItem" class="cart-modal-list-item__heading">
          {{ item.qty }} {{ item.unitName }}{{ heading }}
          <strong class="free-product-label">[滿額贈贈品]</strong>
        </div>
        <div v-else-if="item.type === 'free_product'" class="cart-modal-list-item__heading">
          {{ item.qty }} {{ item.unitName }}{{ heading }}
          <strong class="free-product-label">[贈品]</strong>
        </div>

        <div v-if="item.spec" class="cart-modal-list-item__desc">
          {{ item.spec.title }} {{ item.spec.desc }}
        </div>
        <ul v-if="item.items && (item.items.length > 0)" class="cart-modal-list-item__list">
          <li v-for="row in item.items" :key="row.id">
            <span class="cart-modal-list-item__qty">{{ row.qty }} {{ row.unitName }}</span>
            <span v-if="row.withSpec" class="cart-modal-list-item__spec">{{ row.spec.title }}</span>
            <span>{{ row.title }}</span>
            <strong v-if="row.type === 'free_product'"
                    class="free-product-label">[贈品]</strong>
          </li>
        </ul>
      </div>
    </div>
    <div class="cart-modal-list-item__subtotal">
      <span v-if="item.subtotal === 0">-</span>
      <template v-else>
        小計
        <strong>{{ dollar(item.subtotal) }}</strong>
      </template>
    </div>
    <remove-btn v-if="canRemove"
                data-cy="remove-btn"
                class="cart-modal-list-item__remove-icon"
                @click="showConfirm" />
    <transition name="fade" appear>
      <div v-if="isConfirmVisible"
           class="cart-modal-list-item__confirm">
        <div class="cart-modal-list-item__confirm-content">
          <button class="cart-modal-list-item__cancel-btn" @click="cancel">取消</button>
          <button class="cart-modal-list-item__remove-btn"
                  :disabled="isLoading" @click="$emit('remove-item')">
            {{ btnText }}
          </button>
        </div>
      </div>
    </transition>
  </li>
</template>

<script>
import dollar from '@landing/landing-common/utils/dollar'
import CartModalListItemImage from './CartModalListItemImage'
import RemoveBtn from './RemoveBtn'

export default {
  name: 'CartModalListItem',
  components: {
    CartModalListItemImage,
    RemoveBtn
  },
  props: {
    item: Object,
    hasImageCol: Boolean
  },
  data() {
    return {
      isConfirmVisible: false
    }
  },
  computed: {
    heading() {
      const { title } = this.item
      return title
    },
    isLoading() {
      return this.item.isLoading
    },
    btnText() {
      return this.isLoading ? '刪除中...' : '刪除'
    },
    canRemove() {
      const { item } = this
      if (item.isDiscountLevelItem) {
        return false
      }
      if (item.type === 'free_product') {
        return false
      }
      return true
    }
  },
  methods: {
    dollar,
    cancel() {
      this.isConfirmVisible = false
    },
    showConfirm() {
      this.isConfirmVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-modal-list-item {
  position: relative;
  margin: 14px 14px 0 14px;
  padding: 14px;
}
.cart-modal-list-item__content {
  display: flex;
}
.cart-modal-list-item__info {
  position: relative;
  top: -5px;
  line-height: 1.9;
}
.cart-modal-list-item__qty {
  color: $blue6;
}
.cart-modal-list-item__spec {
  color: $purple1;
}
.cart-modal-list-item__heading {
  font-size: 1em;
  padding-right: 36px;
  font-weight: 500;
  color: $black3;
  strong {
    color: $green3;
  }
  .free-product-label {
    white-space: nowrap;
    color: $purple2;
  }
}
.cart-modal-list-item__subtotal {
  padding-right: 2px;
  font-size: .75em;
  text-align: right;
  strong {
    font-size: 1rem;
    font-weight: 900;
    color: $red1;
  }
}
.cart-modal-list-item__list {
  font-size: .6875em;
  color: $black3;
  > li {
    margin: 4px 0;
  }
}
.cart-modal-list-item__remove-icon {
  position: absolute;
  right: 0;
  top: 0;
}
.cart-modal-list-item__desc {
  line-height: 1.8em;
  font-size: .6875em;
  color: $purple1;
}
.cart-modal-list-item__cancel-btn {
  display: block;
  margin-right: 21px;
  border: 1px solid $white1;
  background-color: transparent;
  padding: 12px 24px;
  border-radius: 2px;
}
.cart-modal-list-item__remove-btn {
  border-radius: 2px;
  display: block;
  background-color: $white1;
  border: 0;
  color: $black0;
  padding: 12px 24px;
  &:disabled {
    opacity: .5;
  }
}
.cart-modal-list-item__confirm {
  padding: 14px;
  color: $white1;
  position: absolute;
  background-color: rgba(0, 0, 0, .7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include flex-center;
}
.cart-modal-list-item__confirm-content {
  display: flex;
}
.free-product-label {
  white-space: nowrap;
  color: $purple2;
}
.cart-modal-list-item__image {
  flex-shrink: 0;
  margin-right: 14px;
}
</style>
