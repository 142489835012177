<template>
  <app-backdrop :is-visible="isVisible" @click="handleCancel">
    <transition name="pop" appear>
      <div v-if="isVisible" class="app-confirm" @click="handleCancel">
        <div class="app-confirm__modal" @click.stop>
          <close-btn class="app-confirm__close-btn"
                     @click="$emit('close')" />
          <div class="app-confirm__title">{{ data.title }}</div>
          <div v-if="Array.isArray(data.message)" class="app-confirm__message">
            <div v-for="(message, i) in data.message" :key="i" v-html="data.message" />
          </div>
          <div v-else class="app-confirm__message" v-html="data.message" />
          <div class="app-confirm__btn-box">
            <button class="btn"
                    @click="handleCancel">
              {{ data.cancelBtnText || '取消' }}
            </button>
            <button class="btn btn-secondary"
                    @click="handleConfirm">
              {{ data.confirmBtnText || '確定' }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </app-backdrop>
</template>

<script>
import getScrollMixin from '@landing/common/mixins/bodyScrollMixin'
import AppBackdrop from './AppBackdrop'
import CloseBtn from './CloseBtn'

const bodyScrollMixin = getScrollMixin('isVisible')

export default {
  name: 'AppConfirm',
  components: {
    AppBackdrop,
    CloseBtn
  },
  mixins: [bodyScrollMixin],
  props: {
    isVisible: Boolean,
    data: Object
  },
  computed: {
    title() {
      const { data } = this
      return data ? data.title : ''
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('close')
      const { confirm } = this.data
      if (typeof confirm === 'function') {
        this.$nextTick(() => confirm())
      }
    },
    handleCancel() {
      this.$emit('close')
      const { cancel } = this.data
      if (typeof cancel === 'function') {
        this.$nextTick(() => cancel())
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-confirm {
  z-index: 1;
  display: flex;
  justify-content: center;
  @include fixed-center;
  margin-left: 14px;
  margin-right: 14px;
}
.app-confirm__modal {
  align-self: center;
  position: relative;
  padding: 14px 20px;
  background-color: $white1;
  border-radius: 4px;
  width: 400px;
}
.app-confirm__backdrop {
  background-color: $black11;
  @include fixed-center;
  z-index: 1;
}
.app-confirm__close-btn {
  @include size(40px);
  position: absolute;
  right: 0;
  top: 0;
}
.app-confirm__title {
  padding-right: 34px;
  font-size: 1.2em;
  font-weight: 400;
}
.app-confirm__message {
  margin: 20px 0 40px;
}
.app-confirm__btn-box {
  display: flex;
  justify-content: flex-end;
}
.btn-secondary {
  margin-left: 14px;
}
</style>
