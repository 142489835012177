import { hideSortMenu, setSortMethod } from '../redux/reducers/app'
import { SORT_METHOD_ROWS } from '@landing/landing-common/consts/sortMethods'
import { animateScrollTo } from '../utils'

export default {
  data() {
    return this.mapState({
      isSortMenuVisible: 'app.isSortMenuVisible',
      siteNavHeight: 'app.siteNavHeight',
      sortMethod: 'app.sortMethod'
    })
  },
  beforeCreate() {
    this.mapDispatch({
      setSortMethod,
      hideSortMenu
    })
  },
  methods: {
    scrollToProductList() {
      const dom = document.getElementById('product-list')
      const offset = -(this.siteNavHeight)
      animateScrollTo(dom, { speed: 30, offset })
    },
    handleCheckmarkBtnClick(method) {
      this.setSortMethod(method)
      this.hideSortMenu()
    }
  },
  SORT_METHOD_ROWS
}
