// lodash
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import isPlainObject from 'lodash.isplainobject'
import pick from 'lodash.pick'
import range from 'lodash.range'
import sortBy from 'lodash.sortby'
import throttle from 'lodash.throttle'

// superlanding
import addPrefix from '@superlanding/addprefix'
import commaSeparate from '@superlanding/commaseparate'
import getApStr from '@superlanding/getapstr'
import getScrollTop from '@superlanding/getscrolltop'
import isClient from '@superlanding/isclient'
import isServer from '@superlanding/isserver'
import toPixel from '@superlanding/topixel'
import toPromise from '@superlanding/topromise'

// redux
import { connect } from 'redux-vuex'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { combineEpics, createEpicMiddleware, ofType } from 'redux-observable'

// rxjs
import { defer, of, from } from 'rxjs'
import {
  catchError,
  delay,
  exhaustMap,
  map,
  retryWhen,
  takeWhile,
  tap
} from 'rxjs/operators'

// others
import ClientOnly from 'vue-client-only'
import { DateTime } from 'luxon'
import Router from 'vue-router'
import { PulseLoader } from '@saeris/vue-spinners'
import animateScrollTo from 'animated-scroll-to'
import anchorMe from 'anchorme'
import { parse as parseUrl } from 'url'
import uuid from 'uuid/v4'
import { zh } from 'vuejs-datepicker/dist/locale'

export {
  // lodash
  get,
  isEmpty,
  isPlainObject,
  pick,
  range,
  sortBy,
  throttle,

  // redux
  applyMiddleware,
  combineEpics,
  combineReducers,
  createEpicMiddleware,
  createStore,
  connect,
  ofType,

  // rxjs
  defer,
  of,
  from,
  catchError,
  delay,
  exhaustMap,
  map,
  retryWhen,
  takeWhile,
  tap,

  // superlanding
  addPrefix,
  commaSeparate,
  getApStr,
  getScrollTop,
  isClient,
  isServer,
  toPixel,
  toPromise,

  // others
  ClientOnly,
  DateTime,
  Router,
  PulseLoader,
  anchorMe,
  animateScrollTo,
  parseUrl,
  uuid,
  zh
}
