import Vue from '../consts/vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft, faTimes, faMinus, faPlus,
  faCheck, faTrashAlt, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faPlus,
  faMinus,
  faChevronLeft,
  faExclamationCircle,
  faExclamationTriangle,
  faTrashAlt,
  faTimes,
  faCheck
)

Vue.component('FaIcon', FontAwesomeIcon)
