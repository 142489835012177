<template>
  <ul class="cart-modal-list">
    <transition-group name="fade">
      <cart-modal-list-item v-for="item in items"
                            :key="item.key"
                            :item="item"
                            :has-image-col="hasImageCol"
                            @remove-item="$emit('remove-item', item)" />
    </transition-group>
  </ul>
</template>

<script>
import CartModalListItem from './CartModalListItem'

export default {
  name: 'CartModalList',
  components: {
    CartModalListItem
  },
  props: {
    items: Array
  },
  computed: {
    hasImageCol() {
      return this.items.some(item => item.source && item.source.image)
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-modal-list {
  display: block;
}
.cart-modal-list__item-qty {
  color: $blue6;
}
.cart-modal-list__item-spec {
  color: $purple1;
}
</style>
