<template>
  <div id="product-list"
       v-observe-visibility="{ callback: handleVisibilityChange }"
       class="product-list">
    <div v-if="productBanner" class="product-list__product-banner-box">
      <app-image class="product-list__banner"
                 :class="{ 'product-list__banner--error': hasImageError(productBanner) }"
                 :src="productBanner"
                 alt="product banner"
                 lazy
                 @error="saveErrorImageSrc(productBanner)" />
    </div>
    <template v-for="type in types">
      <product-list-item-group v-if="type === 'promo'"
                               :key="`${type}-promoGroup`"
                               ref="promoGroup"
                               data-cy="promo-list"
                               :items="promos"
                               :mode="mode"
                               @primary-btn-click="handleItemClick"
                               @secondary-btn-click="handleAdditionalPurchaseItemClick" />

      <div v-if="(type === 'product') && hasProducts"
           :key="`${type}-headingBar`"
           class="product-list__heading-bar">
        <h2 class="product-list__heading">全部商品</h2>
        <sort-btn aria-label="排序商品" @click="showSortMenu" />
      </div>
      <product-list-item-group v-if="(type === 'product') && hasProducts"
                               :key="`${type}-productGroup`"
                               ref="productGroup"
                               data-cy="product-list"
                               :items="sortedProducts"
                               :mode="mode"
                               @primary-btn-click="handleItemClick"
                               @secondary-btn-click="handleAdditionalPurchaseItemClick" />
      <div v-if="(type === 'product') && addtionalProductBanner"
           :key="`${type}-addtionalProductBanner`"
           class="product-list__additional-product-banner-box">
        <app-image class="product-list__banner"
                   :class="{ 'product-list__banner--error': hasImageError(addtionalProductBanner) }"
                   :src="addtionalProductBanner"
                   alt="additional product banner"
                   lazy
                   @error="saveErrorImageSrc(addtionalProductBanner)" />
      </div>
      <product-list-item-group v-if="(type === 'product') && hasAdditionalProducts"
                               :key="`${type}-additionalProductGroup`"
                               ref="additionalProductGroup"
                               data-cy="additional-product-list"
                               :items="sortedAdditionalProducts"
                               :mode="mode"
                               @primary-btn-click="handleItemClick"
                               @secondary-btn-click="handleAdditionalPurchaseItemClick" />
    </template>
  </div>
</template>

<script>
import AppImage from '@landing/landing-mobile/components/AppImage'
import ProductListItemGroup from '@landing/landing-mobile/components/ProductListItemGroup'
import SortBtn from '@landing/landing-mobile/components/SortBtn'
import domMixin from '@landing/common/mixins/domMixin'
import imageErrorMixin from '@landing/landing-mobile/mixins/imageErrorMixin'
import { setCenterToast } from '@landing/landing-mobile/redux/reducers/toast'
import { showSortMenu, showOrderItemMenu } from '@landing/landing-mobile/redux/reducers/app'
import { sortBy, throttle } from '@landing/landing-mobile/utils'
import * as sortMethods from '@landing/landing-common/consts/sortMethods'

export default {
  name: 'ProductList',
  components: {
    AppImage,
    ProductListItemGroup,
    SortBtn
  },
  mixins: [domMixin, imageErrorMixin],
  props: {
    promos: Array,
    products: Array,
    addtionalProducts: Array,
    productBanner: String,
    addtionalProductBanner: String,
    mode: {
      type: String,
      default: 'list'
    },
    priority: String
  },
  data() {
    return {
      contentViewed: false,
      ...this.mapState({
        sortMethod: 'app.sortMethod',
        cartItems: 'cart.cart.items',
        trackerLoaded: 'app.trackerLoaded'
      })
    }
  },
  computed: {
    hasProducts() {
      return (this.products.length > 0)
    },
    hasAdditionalProducts() {
      return (this.addtionalProducts.length > 0)
    },
    types() {
      if (this.priority === 'promos') {
        return ['promo', 'product']
      }
      return ['product', 'promo']
    },
    sortedProducts() {
      return this.sort(this.products)
    },
    sortedAdditionalProducts() {
      return this.sort(this.addtionalProducts)
    }
  },
  beforeCreate() {
    this.mapDispatch({ showSortMenu, showOrderItemMenu, setCenterToast })
  },
  mounted() {
    this.initViewContent()
    this.on(window, 'scroll', throttle(this.handleScroll, 100))
  },
  methods: {
    resetViewContent() {
      (this.unbinds || []).forEach(unbind => unbind())
      this.contentViewed = false
    },
    initViewContent() {
      this.resetViewContent()
      const fn = () => this.viewContent()
      this.unbinds = []
      this.unbinds.push(this.$watch('trackerLoaded', fn))
      this.unbinds.push(this.$watch('contentViewed', fn))
    },
    viewContent() {
      if (this.trackerLoaded && this.contentViewed) {
        this.$tracker.viewContent({
          sortedProductMap: this.getSortedProductMap()
        })
        this.resetViewContent()
      }
    },
    handleScroll() {
      this.setCenterToast('')
    },
    sort(rows) {
      const { sortMethod } = this
      if (sortMethod === sortMethods.NEWEST_FIRST) {
        return sortBy(rows, row => row.priority)
      }
      if (sortMethod === sortMethods.PRICE_ASC) {
        return sortBy(rows, row => row.price)
      }
      if (sortMethod === sortMethods.PRICE_DESC) {
        return sortBy(rows, row => row.price).reverse()
      }
      return rows
    },
    getSortedProductMap() {
      return this.sortedProducts.reduce((obj, row, index) => {
        obj[row.id] = (index + 1)
        return obj
      }, {})
    },
    handleVisibilityChange(visible) {
      if (visible) {
        this.contentViewed = true
      }
    },
    handleItemClick(item) {
      this.showOrderItemMenu(item)
    },
    handleAdditionalPurchaseItemClick(item) {
      if (this.cartItems.length === 0) {
        if (this.centerToastTimer) {
          clearTimeout(this.centerToastTimer)
          this.centerToastTimer = null
        }
        this.setCenterToast('請先選擇產品，才可加購')
        this.centerToastTimer = setTimeout(() => this.setCenterToast(''), 2000)
        return
      }
      this.showOrderItemMenu(item)
    }
  }
}
</script>

<style lang="scss" scoped>
.product-list__heading {
  padding-top: 14px;
  padding-bottom: 14px;
  margin: 0;
  font-size: 1.125em;
  color: $black5;
}
.product-list__heading-bar {
  padding: 0 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-list__banner {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  &.product-list__banner--error {
    width: 375px;
    height: 100px;
  }
}
.product-list__product-banner-box {
  padding: 14px 14px 0;
}
.product-list__additional-product-banner-box {
  padding: 0 14px 14px;
}
</style>
