import app from './app'
import alert from './alert'
import toast from './toast'
import confirm from './confirm'
import cart from './cart'
import order from './order'
import page from './page'
import { combineReducers } from '@landing/landing-mobile/utils'

export const CLEAR_STORE = '@landing-mobile/root/CLEAR_STORE'

export const clearStore = () => {
  return {
    type: CLEAR_STORE
  }
}

const reducer = combineReducers({
  app,
  alert,
  confirm,
  page,
  toast,
  cart,
  order
})

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    return reducer(undefined, action)
  }
  return reducer(state, action)
}

export default rootReducer
