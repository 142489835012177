<template>
  <div class="title-bar">
    <div v-if="title" class="title-bar__title">{{ title }}</div>
    <app-image v-if="logoUrl" class="title-bar__logo"
               :src="logoUrl" />
    <close-btn v-if="canClose" class="title-bar__close-btn"
               @click="$emit('close')" />
  </div>
</template>

<script>
import AppImage from './AppImage'
import CloseBtn from './CloseBtn'

export default {
  name: 'TitleBar',
  components: {
    AppImage,
    CloseBtn
  },
  props: {
    title: String,
    logoUrl: String,
    canClose: Boolean
  }
}
</script>

<style lang="scss" scoped>
.title-bar {
  min-height: 53px;
  z-index: 1;
  @include shadow;
  position: relative;
  background-color: $white1;
  font-size: 1.125em;
  padding: 14px 0;
  overflow: hidden;
}
.title-bar__title {
  text-align: center;
}
.title-bar__logo {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  width: auto;
  max-height: 36px;
}
.title-bar__close-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  font-size: 1.3em;
}
</style>
