import DiscountLv from '@landing/common/models/DiscountLv'
import Promo from '@landing/landing-common/models/Promo'
import Product from '@landing/landing-common/models/Product'
import { DEFAULT_CONTENT_DATA } from '@landing/landing-common/consts'

export const SET_PAGE = '@landing-mobile/page/SET_PAGE'
export const SET_SITE = '@landing-mobile/page/SET_SITE'

export const SET_PRODUCT_SPEC_STATUS = '@landing-mobile/page/SET_PRODUCT_SPEC_STATUS'


export const setPage = data => {
  return {
    type: SET_PAGE,
    data
  }
}

export const setSite = data => {
  return {
    type: SET_SITE,
    data
  }
}

export const setProductSpecStatus = ({ productId, specId, status }) => {
  return {
    type: SET_PRODUCT_SPEC_STATUS,
    productId,
    specId,
    status
  }
}

const initialState = {
  page: {
    products: [],
    promos: []
  }
}

export default function page(state = initialState, action) {

  switch (action.type) {
    case SET_PRODUCT_SPEC_STATUS: {
      const { specId, status, productId } = action
      const changeSpecStatus = product => {
        if (product.id === productId) {
          const newProduct = new Product(product)
          newProduct.specs = product.specs.map(spec => {
            if (spec.id === specId) {
              spec.status = status
            }
            return spec
          })
          return newProduct
        }
        return product
      }
      const products = (state.page.products || []).map(changeSpecStatus)
      const addtionalProducts = (state.page.addtionalProducts || []).map(changeSpecStatus)
      return { ...state, page: { ...state.page, products, addtionalProducts } }
    }
    case SET_PAGE: {
      const { data } = action
      if (! data) {
        return state
      }
      const products = (data.products || []).map(row => new Product(row))
      const addtionalProducts = (data.addtionalProducts || []).map(row => new Product(row))
        .map(row => row.showAsAdditionalPurchase())
      const promos = (data.promos || []).map(row => new Promo(row))
      const discountLevels = (data.discountLevels || []).map(row => DiscountLv.from(row))
      const content = data.content || DEFAULT_CONTENT_DATA
      return { ...state, page: { ...data, discountLevels, products, addtionalProducts, promos, content } }
    }
    case SET_SITE: {
      if (! action.data) {
        return state
      }
      return { ...state, page: { ...state.page, site: { ...action.data } } }
    }
    default:
      return state
  }
}
