import OrderItem from '@landing/landing-common/models/OrderItem'
import IntlAddress from '@common/models/IntlAddress'

export const GET_CART_DATA = '@landing-mobile/cart/GET_CART_DATA'
export const GET_CART_DATA_LOADING = '@landing-mobile/cart/GET_CART_DATA_LOADING'
export const GET_CART_DATA_SUCCESS = '@landing-mobile/cart/GET_CART_DATA_SUCCESS'
export const GET_CART_DATA_FAIL = '@landing-mobile/cart/GET_CART_DATA_FAIL'

export const ADD_ORDER_PRODUCT = '@landing-mobile/cart/ADD_ORDER_PRODUCT'
export const ADD_ORDER_PRODUCT_LOADING = '@landing-mobile/cart/ADD_ORDER_PRODUCT_LOADING'
export const ADD_ORDER_PRODUCT_SUCCESS = '@landing-mobile/cart/ADD_ORDER_PRODUCT_SUCCESS'
export const ADD_ORDER_PRODUCT_FAIL = '@landing-mobile/cart/ADD_ORDER_PRODUCT_FAIL'

export const ADD_ORDER_PROMO = '@landing-mobile/cart/ADD_ORDER_PROMO'
export const ADD_ORDER_PROMO_LOADING = '@landing-mobile/cart/ADD_ORDER_PROMO_LOADING'
export const ADD_ORDER_PROMO_SUCCESS = '@landing-mobile/cart/ADD_ORDER_PROMO_SUCCESS'
export const ADD_ORDER_PROMO_FAIL = '@landing-mobile/cart/ADD_ORDER_PROMO_FAIL'

export const REMOVE_ORDER_PRODUCT = '@landing-mobile/cart/REMOVE_ORDER_PRODUCT'
export const REMOVE_ORDER_PRODUCT_SUCCESS = '@landing-mobile/cart/REMOVE_ORDER_PRODUCT_SUCCESS'
export const REMOVE_ORDER_PRODUCT_FAIL = '@landing-mobile/cart/REMOVE_ORDER_PRODUCT_FAIL'

export const SET_CART = '@landing-mobile/cart/SET_CART'
export const SET_PAGE_TO_CART = '@landing-mobile/cart/SET_PAGE_TO_CART'

export const ADD_FREE_PRODUCT_SET = '@landing-mobile/cart/ADD_FREE_PRODUCT_SET'
export const ADD_FREE_PRODUCT_SET_LOADING = '@landing-mobile/cart/ADD_FREE_PRODUCT_SET_LOADING'
export const ADD_FREE_PRODUCT_SET_SUCCESS = '@landing-mobile/cart/ADD_FREE_PRODUCT_SET_SUCCESS'
export const ADD_FREE_PRODUCT_SET_FAIL = '@landing-mobile/cart/ADD_FREE_PRODUCT_SET_FAIL'

export const SET_COUPON = '@landing-mobile/cart/SET_COUPON'
export const SET_COUPON_SUCCESS = '@landing-mobile/cart/SET_COUPON_SUCCESS'
export const SET_COUPON_FAIL = '@landing-mobile/cart/SET_COUPON_FAIL'

export const SUBMIT_STANDARD_FORM = '@landing-mobile/cart/SUBMIT_STANDARD_FORM'
export const SUBMIT_STANDARD_FORM_LOADING = '@landing-mobile/cart/SUBMIT_STANDARD_FORM_LOADING'
export const SUBMIT_STANDARD_FORM_SUCCESS = '@landing-mobile/cart/SUBMIT_STANDARD_FORM_SUCCESS'
export const SUBMIT_STANDARD_FORM_FAIL = '@landing-mobile/cart/SUBMIT_STANDARD_FORM_FAIL'

export const SUBMIT_MULTI_STEP_FORM = '@landing-mobile/cart/SUBMIT_MULTI_STEP_FORM'
export const SUBMIT_MULTI_STEP_FORM_LOADING = '@landing-mobile/cart/SUBMIT_MULTI_STEP_FORM_LOADING'
export const SUBMIT_MULTI_STEP_FORM_SUCCESS = '@landing-mobile/cart/SUBMIT_MULTI_STEP_FORM_SUCCESS'
export const SUBMIT_MULTI_STEP_FORM_FAIL = '@landing-mobile/cart/SUBMIT_MULTI_STEP_FORM_FAIL'

export const submitStandardForm = data => {
  const postData = { cart: { ...data, terms: true } }
  return {
    type: SUBMIT_STANDARD_FORM,
    data: postData,
    async: {
      loading: SUBMIT_STANDARD_FORM_LOADING,
      resolve: SUBMIT_STANDARD_FORM_SUCCESS,
      reject: SUBMIT_STANDARD_FORM_FAIL
    }
  }
}

export const submitMultiStepForm = (step, data) => {
  const postData = {
    cart: { ...data, currentStep: step }
  }
  return {
    type: SUBMIT_MULTI_STEP_FORM,
    data: postData,
    async: {
      loading: SUBMIT_MULTI_STEP_FORM_LOADING,
      resolve: SUBMIT_MULTI_STEP_FORM_SUCCESS,
      reject: SUBMIT_MULTI_STEP_FORM_FAIL
    }
  }
}

export const setCoupon = couponCode => {
  const data = {
    cart: { couponCode }
  }
  return {
    type: SET_COUPON,
    data,
    async: {
      resolve: SET_COUPON_SUCCESS,
      reject: SET_COUPON_FAIL
    }
  }
}

export const addFreeProductSet = (orderProductId, data) => {
  const postData = {
    orderProduct: {
      freeProducts: data
    }
  }
  return {
    type: ADD_FREE_PRODUCT_SET,
    orderProductId,
    data: postData,
    async: {
      loading: ADD_FREE_PRODUCT_SET_LOADING,
      resolve: ADD_FREE_PRODUCT_SET_SUCCESS,
      reject: ADD_FREE_PRODUCT_SET_FAIL
    }
  }
}

export const addOrderProduct = data => {
  const { id, qty, specId } = data
  const postData = {
    orderProduct: { qty }
  }
  if (specId) {
    postData.orderProduct.specId = specId
  }
  return {
    type: ADD_ORDER_PRODUCT,
    productId: id,
    data: postData,
    async: {
      loading: ADD_ORDER_PRODUCT_LOADING,
      resolve: ADD_ORDER_PRODUCT_SUCCESS,
      reject: ADD_ORDER_PRODUCT_FAIL
    }
  }
}

export const addOrderPromo = data => {
  const postData = {
    orderProduct: {
      products: data.products.map(row => {
        const product = {
         productable_id: row.id,
         qty: row.qty
        }
        if (row.specId) {
          product.spec_id = row.specId
        }
        return product
      })
    }
  }
  return {
    type: ADD_ORDER_PROMO,
    promoId: data.id,
    data: postData,
    async: {
      loading: ADD_ORDER_PROMO_LOADING,
      resolve: ADD_ORDER_PROMO_SUCCESS,
      reject: ADD_ORDER_PROMO_FAIL
    }
  }
}

export const removeOrderProduct = orderProductId => {
  return {
    type: REMOVE_ORDER_PRODUCT,
    orderProductId,
    async: {
      resolve: REMOVE_ORDER_PRODUCT_SUCCESS,
      reject: REMOVE_ORDER_PRODUCT_FAIL
    }
  }
}

export const setPageToCart = page => {
  return {
    type: SET_PAGE_TO_CART,
    page
  }
}

export const setCart = cart => {
  return {
    type: SET_CART,
    cart
  }
}

export const getCartData = () => {
  return {
    type: GET_CART_DATA,
    async: {
      resolve: GET_CART_DATA_SUCCESS,
      reject: GET_CART_DATA_FAIL
    }
  }
}

const initialState = {
  isSubmittingStandardForm: false,
  errorSubmittingStandardForm: null,
  isSubmittingMultiStepForm: false,
  errorSubmittingMultiStepForm: null,
  isLoadingCart: false,
  isCartLoaded: false,
  isCartLoadingFailed: false,
  errorLoadingCart: null,
  isAddingOrderProduct: false,
  isAddingOrderPromo: false,
  isAddingFreeProductSet: false,
  cart: {
    id: 0,
    items: [],
    type: 'Cart',
    info: {
      shippingMethod: '',
      paymentMethod: '',
      recipient: '',
      mobile: '',
      email: '',
      note: '',
      lineId: '',
      birthday: '',
      address: ''
    },
    couponCode: '',
    shippingFee: 0,
    freeShippingBalance: 0,
    orderAmount: 0,
    totalOrderAmount: 0,
    totalOrderAmountWithDiscount: 0,
    sessionId: '',
    persisted: false
  }
}

const mapCart = (state, cart) => {
  const address = IntlAddress.from(cart.info.address)
  const info = { ...cart.info, address }
  const mutation = {
    info,
    items: cart.items.map(item => new OrderItem(item))
  }
  return Object.assign({}, state.cart, cart, mutation)
}

export default function cart(state = initialState, action) {

  switch (action.type) {

    case SUBMIT_STANDARD_FORM:
      return { ...state, errorSubmittingStandardForm: null }
    case SUBMIT_STANDARD_FORM_LOADING:
      return { ...state, isSubmittingStandardForm: true }
    case SUBMIT_STANDARD_FORM_SUCCESS:
      return { ...state, isSubmittingStandardForm: false }
    case SUBMIT_STANDARD_FORM_FAIL: {
      return {
        ...state,
        errorSubmittingStandardForm: action.payload,
        isSubmittingStandardForm: false,
      }
    }
    case SUBMIT_MULTI_STEP_FORM:
      return { ...state, errorSubmittingMultiStepForm: null }
    case SUBMIT_MULTI_STEP_FORM_LOADING:
      return { ...state, isSubmittingMultiStepForm: true }
    case SUBMIT_MULTI_STEP_FORM_SUCCESS: {
      const { cart } = action.payload
      if (cart) {
        return {
          ...state,
          isSubmittingMultiStepForm: false,
          cart: mapCart(state, cart)
        }
      }
      return { ...state, isSubmittingMultiStepForm: false }
    }
    case SUBMIT_MULTI_STEP_FORM_FAIL:
      return {
        ...state,
        errorSubmittingMultiStepForm: action.payload,
        isSubmittingMultiStepForm: false,
      }
    case SET_COUPON:
      return state
    case SET_COUPON_SUCCESS: {
      return { ...state, cart: mapCart(state, action.payload.cart) }
    }
    case SET_COUPON_FAIL:
      return state
    case ADD_FREE_PRODUCT_SET_LOADING:
      return { ...state, isAddingFreeProductSet: true }
    case ADD_FREE_PRODUCT_SET_SUCCESS: {
      const { orderProductId } = action
      const items = state.cart.items.map(item => {
        if (item.id === orderProductId) {
          const freeProductSet = Object.assign({}, item.freeProductSet, { used: true })
          const newItem = Object.assign({}, item, { freeProductSet })
          return new OrderItem(newItem)
        }
        return item
      })
      const cart = Object.assign({}, state.cart, { items })
      return { ...state, isAddingFreeProductSet: false, cart }
    }
    case ADD_FREE_PRODUCT_SET_FAIL:
      return { ...state, isAddingFreeProductSet: false }

    case SET_PAGE_TO_CART:
      return { ...state, page: action.page }

    case SET_CART: {
      const { cart } = action
      if (! cart) {
        return state
      }
      return { ...state, cart: mapCart(state, cart) }
    }
    case ADD_ORDER_PROMO_LOADING:
      return { ...state, isAddingOrderPromo: true }
    case ADD_ORDER_PROMO_SUCCESS:
      return { ...state, cart: mapCart(state, action.payload.cart), isAddingOrderPromo: false }
    case ADD_ORDER_PROMO_FAIL:
      return { ...state, isAddingOrderPromo: false }
    case ADD_ORDER_PRODUCT_LOADING:
      return { ...state, isAddingOrderProduct: true }
    case ADD_ORDER_PRODUCT_SUCCESS: {
      return {
        ...state,
        cart: mapCart(state, action.payload.cart),
        isAddingOrderProduct: false
      }
    }
    case ADD_ORDER_PRODUCT_FAIL:
      return { ...state, isAddingOrderProduct: false }
    case REMOVE_ORDER_PRODUCT:
      return { ...state }
    case REMOVE_ORDER_PRODUCT_SUCCESS: {
      const { cart } = action.payload
      return { ...state, cart: mapCart(state, cart) }
    }
    case REMOVE_ORDER_PRODUCT_FAIL:
      return { ...state }
    case GET_CART_DATA:
      return {
        ...state,
        errorLoadingCart: null,
        isLoadingCart: true,
        isCartLoaded: false,
        isCartLoadingFailed: false
      }
    case GET_CART_DATA_SUCCESS: {
      return {
        ...state,
        cart: mapCart(state, action.payload),
        isLoadingCart: false,
        isCartLoaded: true
      }
    }
    case GET_CART_DATA_FAIL: {
      return {
        ...state,
        errorLoadingCart: action.payload,
        isLoadingCart: false,
        isCartLoaded: true,
        isCartLoadingFailed: true
      }
    }
    default:
      return state
  }
}
