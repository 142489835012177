<template>
  <nav v-if="isNavContainerVisible" class="app-nav">
    <div v-if="siteNavVisible" class="site-nav" data-cy="site-nav">
      <button aria-label="Toggle Navigation"
              class="btn-icon site-nav__toggle-btn"
              @click="$emit('toggle-btn-click')">
        <span class="icon-bar" />
      </button>
      <div class="site-nav__logo">
        <a :href="getSiteUrl(page.site)">
          <app-image class="site-nav__brand"
                     :src="page.site.logoUrl.mobile"
                     :alt="page.site.title" />
        </a>
      </div>
    </div>
    <navbar-element v-if="navbarVisible" :data="navbar" />
  </nav>
</template>
<script>
import NavbarElement from '@landing/common/components/NavbarElement'
import getSiteUrl from '@landing/common/utils/getSiteUrl'
import AppImage from './AppImage'

export default {
  name: 'AppNav',
  components: {
    AppImage,
    NavbarElement
  },
  props: {
    page: Object,
    siteNavVisible: Boolean,
    navbarVisible: Boolean,
    mounted: Function,
    updated: Function
  },
  computed: {
    navbar() {
      return this.get(this, 'page.content.navbar')
    },
    isNavContainerVisible() {
      return this.siteNavVisible || this.navbarVisible
    }
  },
  mounted() {
    if (this.mounted) {
      this.mounted(this.$el)
    }
  },
  updated() {
    if (this.updated) {
      this.updated(this.$el)
    }
  },
  methods: {
    getSiteUrl
  }
}
</script>

<style lang="scss" scoped>
.app-nav {
  z-index: 3;
  position: fixed;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 0;
}
.site-nav {
  position: relative;
}
.site-nav__brand {
  @include absolute-center;
  width: auto;
  height: 45px;
}

.site-nav__toggle-btn {
  width: 50px;
  height: 50px;
  position: relative;
  .icon-bar {
    &,
    &:before,
    &:after {
      background-color: #888;
      display: block;
      position: absolute;
      content: '';
      width: 22px;
      height: 2px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
    & {
      top: 16px;
    }
    &:before {
      top: 6px;
    }
    &:after {
      top: 12px;
    }
  }
}
</style>
