<template>
  <vertical-menu v-if="item"
                 :is-visible="isVisible"
                 :scrollable="isProduct"
                 :heading="item.title"
                 @backdrop-click="close"
                 @close="close">
    <template v-if="item.isAdditionalPurchase" #label>
      <strong class="order-item-menu__label">[加購]</strong>
    </template>
    <order-item-menu-product v-if="isProduct" :item="item" @submit="handleSubmit" />
    <order-item-menu-promo v-if="isPromo" :item="item" :cart="cart" :page="page" @submit="handleSubmit" />
  </vertical-menu>
</template>

<script>
import OrderItemMenuProduct from '@landing/landing-mobile/components/OrderItemMenuProduct'
import OrderItemMenuPromo from '@landing/landing-mobile/components/OrderItemMenuPromo'
import VerticalMenu from '@landing/landing-mobile/components/VerticalMenu'
import { showAlert } from './../redux/reducers/alert'
import Product from '@landing/landing-common/models/Product'
import Promo from '@landing/landing-common/models/Promo'
import isValidDiscountCount from '@common/utils/isValidDiscountCount'
import { getApStr } from '@landing/landing-mobile/utils'

export default {
  name: 'OrderItemMenu',
  components: {
    OrderItemMenuProduct,
    OrderItemMenuPromo,
    VerticalMenu
  },
  props: {
    isVisible: Boolean,
    item: Object
  },
  data() {
    return this.mapState({
      cart: 'cart.cart',
      page: 'page.page'
    })
  },
  computed: {
    isPromo() {
      return this.item instanceof Promo
    },
    isProduct() {
      return this.item instanceof Product
    }
  },
  beforeCreate() {
    this.mapDispatch({ showAlert })
  },
  methods: {
    validatePromo(data) {
      const { promoType } = this.item
      const { qty, interval, rate } = this.item.promoDetails
      const productCount = data.products.reduce((count, row) => count + row.qty, 0)

      if (productCount === 0) {
        return {
          message: '請先選擇組合商品',
          btnText: '好'
        }
      }
      if (productCount < qty) {
        return {
          message: `請再選擇 ${qty - productCount} 個商品`,
          btnText: '好'
        }
      }
      if ((promoType === 'discounts') && (! isValidDiscountCount(productCount, qty, interval))) {
        const apStr = getApStr({ qty, interval })
        if (rate >= 1) {
          return {
            message: `請把商品數量調整為 ${apStr} ...個`,
            btnText: '好'
          }
        }
        if (interval === 0) {
          return {
            message: `請把商品數量調整為 ${qty} 個才有折扣優惠`,
            btnText: '好'
          }
        }
        return {
          message: `請把商品數量調整為 ${apStr} ...個才有折扣優惠`,
          btnText: '好'
        }
      }
      if ((promoType !== 'discounts') && (productCount > qty)) {
        return {
          message: `選太多了啦! 請把商品數量調整為 ${qty} 個`,
          btnText: '好'
        }
      }
    },
    handleSubmit(data) {
      let error
      if (this.isPromo) {
        error = this.validatePromo(data)
      }
      if (error) {
        return this.showAlert(error)
      }
      this.$emit('submit', data)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.order-item-menu {
  padding: 0 14px 14px;
}
.order-item-menu__img {
  display: block;
  width: 100%;
  height: auto;
}
.order-item-menu__form {
  label {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}
.btn-primary {
  margin-top: 48px;
}
.order-item-menu__label {
  color: $blue6;
}
</style>
