// cartMixin requires domMixin to work properly
import { addOrderProduct, addOrderPromo,
  addFreeProductSet, getCartData } from '../redux/reducers/cart'
import { showFreeProductSetMenu, hideFreeProductSetMenu,
  showCartModal, hideCartModal, hideOrderItemMenu } from '../redux/reducers/app'
import { setProductSpecStatus } from '../redux/reducers/page'
import HumanError from '@landing/landing-common/models/HumanError'
import { throttle } from '../utils'

export default {
  data() {
    return {
      freeProductSet: null,
      freeProductSetOrderProductId: null,
      tooltipMessage: '',
      ...this.mapState({
        page: 'page.page',
        isOrderItemMenuVisible: 'app.isOrderItemMenuVisible',
        isLoadingCart: 'cart.isLoadingCart',
        isCheckoutFormVisible: 'app.isCheckoutFormVisible',
        isFreeProductSetMenuVisible: 'app.isFreeProductSetMenuVisible',
        selectedOrderItem: 'app.selectedOrderItem',
        cartItems: 'cart.cart.items',
        cartData: 'cart.cart',
        isCartModalVisible: 'app.isCartModalVisible'
      })
    }
  },
  beforeCreate() {
    this.mapDispatch({
      addFreeProductSet,
      addOrderProduct,
      addOrderPromo,
      getCartData,
      hideCartModal,
      hideFreeProductSetMenu,
      hideOrderItemMenu,
      setProductSpecStatus,
      showCartModal,
      showFreeProductSetMenu
    })
    this.tooltipTimer = null
    this.scrollTimes = 0
  },
  mounted() {
    this.init()
    this.on(window, 'scroll', throttle(this.handleScroll, 100))
  },
  computed: {
    checkoutBarBtnText() {
      return this.isCartModalVisible ? '下一步' : '立即結帳'
    }
  },
  methods: {
    async init() {

      if (this.isClient) {
        try {
          await this.getCartData()
          this.showFreeProductSetMenuIfNeeded()
        }
        catch (err) {
          const message = HumanError.getMessage(err)
          if (message) {
            this.addToastItem({ message })
          }
        }
        if (typeof this.cartDataReady === 'function') {
          this.cartDataReady()
        }
      }
    },
    async handleCartClick() {
      this.tooltipMessage = ''
      try {
        await this.getCartData()
        if (this.cartItems.length === 0) {
          this.showConfirm({
            message: '您目前尚未選購商品無法結帳，是否直接選購商品？',
            confirmBtnText: '好喔',
            cancelBtnText: '先不用',
            confirm: () => this.scrollToProductList()
          })
        }
        else {
          this.showCartModal()
        }
      }
      catch (err) {
        const message = HumanError.getMessage(err)
        if (message) {
          this.addToastItem({ message })
        }
      }
    },
    handleScroll() {
      // 關閉新增商品視窗可能會觸發 handleScroll
      // 捲動觸發三次才視為一般使用者
      this.scrollTimes += 1
      if (this.scrollTimes > 3) {
        this.tooltipMessage = ''
        this.scrollTimes = 0
      }
    },
    showFreeProductSetMenuIfNeeded() {

      const itemWithfreeProductSet = this.cartItems.find(item => {
        return item.freeProductSet && (! item.freeProductSet.used)
      })

      if (itemWithfreeProductSet) {
        this.freeProductSetOrderProductId = itemWithfreeProductSet.id
        this.freeProductSet = itemWithfreeProductSet.freeProductSet
        this.showFreeProductSetMenu()
      }
    },
    handleCheckout() {
      if (! this.isCartModalVisible) {
        this.showCartModal()
        return
      }
      const { layout } = this.page
      const params = {
        aliasName: this.cartData.page.aliasName
      }
      const { query } = this
      const cart = this.cartData
      const trackingData = {
        cartId: cart.id,
        cart,
        totalOrderAmount: cart.totalOrderAmountWithDiscount,
        title: this.page.title
      }
      if (layout.checkout === 'multi_step') {
        query.step = 1
        trackingData.step = 1
      }
      this.$tracker.checkout(trackingData)
      this.hideCartModal()
      this.$router.push({ name: 'CheckoutPage', params, query })
    },
    reloadCart() {
      this.getCartData()
    },
    async handleOrderItemMenuSubmit(data) {

      const isProduct = (data.type === 'product')

      if (this.tooltipTimer) {
        clearTimeout(this.tooltipTimer)
        this.tooltipTimer = null
      }
      this.scrollTimes = 0

      try {
        let res
        if (isProduct) {
          res = await this.addOrderProduct(data)
          this.tooltipMessage = '已新增商品到購物車'
        }
        else {
          res = await this.addOrderPromo(data)
          this.tooltipMessage = '已新增組合到購物車'
        }
        this.hideOrderItemMenu()
        this.tooltipTimer = setTimeout(() => this.tooltipMessage = '', 3000)
        const { id, title, price, qty } = res.payload.orderProduct
        const { cart } = this
        const { currency } = this.page

        this.$tracker.addToCart({
          productId: id,
          title,
          price,
          qty,
          totalAmount: qty * price,
          currency,
          cart
        })
      }
      catch (err) {
        const specIdError = this.get(err, 'payload.response.data.errors.specId[0]')
        if (specIdError) {
          this.addToastItem({ message: specIdError })
          if (specIdError === '此產品規格可能已被刪除，請重新選擇！') {
            this.setProductSpecStatus({
              productId: data.id,
              specId: data.specId,
              status: 'out_of_stock'
            })
          }
          return
        }
        const message = HumanError.getMessage(err)
        if (message) {
          this.addToastItem({ message })
        }
      }
      this.showFreeProductSetMenuIfNeeded()
    },
    handleFreeProductSetMenuSubmit(selectedProductsMap) {
      const { qty } = this.freeProductSet
      const selectedQty = Object.values(selectedProductsMap)
        .reduce((prev, curr) => prev + curr, 0)

      if (selectedQty < qty) {
        return this.showAlert({
          message: `你還可以再選 ${qty - selectedQty} 個!`,
          btnText: '好'
        })
      }
      if (selectedQty > qty) {
        return this.showAlert({
          message: `只能選 ${qty} 個, 你選了 ${selectedQty} 個!`,
          btnText: '好'
        })
      }
      const data = Object.keys(selectedProductsMap)
        .map(prop => ({ id: parseInt(prop, 10), qty: selectedProductsMap[prop] }))

      const orderProductId = this.freeProductSetOrderProductId

      this.addFreeProductSet(orderProductId, data)
        .then(() => {
          this.freeProductSet = null
          this.freeProductSetOrderProductId = null
        })
      this.hideFreeProductSetMenu()
    }
  }
}
