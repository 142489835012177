<template>
  <div>
    <button class="dropdown__toggle"
            type="button"
            :class="{ 'dropdown__toggle--opened': opened }"
            @click.prevent="opened = ! opened">
      {{ item.title }}
    </button>
    <ul class="dropdown-menu" :class="{ 'dropdown-menu--opened': opened }">
      <li v-for="page in item.pages" :key="page.id">
        <a :href="page.url" @click="handleAnchorClick($event, page.url)">
          <app-image class="dropdown-menu-item__img"
                     :class="{ 'dropdown-menu-item__img--error': hasImageError(page.image) }"
                     :src="page.image"
                     :alt="page.title"
                     @error="saveErrorImageSrc(page.image)" />
        </a>
        <div class="dropdown-menu-item__title">{{ page.title }}</div>
      </li>
    </ul>
  </div>
</template>
<script>
import imageErrorMixin from '@landing/landing-mobile/mixins/imageErrorMixin'
import AppImage from './AppImage'

export default {
  name: 'DropdownMenu',
  components: {
    AppImage
  },
  mixins: [imageErrorMixin],
  props: {
    item: Object,
    defaultOpened: {
      type: Boolean,
      value: false
    }
  },
  data() {
    return {
      opened: this.defaultOpened
    }
  },
  methods: {
    handleAnchorClick(event, url) {
      this.$emit('anchor-click', { event, url })
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-menu {
  height: 0;
  overflow: hidden;
  > li {
    padding: 0 14px 14px 14px;
  }
}
.dropdown-menu-item__title {
  font-size: .8em;
  padding-top: 4px;
}
.dropdown-menu.dropdown-menu--opened {
  height: initial;
  overflow: hidden;
  background-color: $ex-bg-navbar-menu;
}
.dropdown__toggle {
  display: block;
  background-color: transparent;
  text-align: left;
  border: 0;
  width: 100%;
  padding: 14px;
}
.dropdown__toggle:after {
  transition: .2s all;
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-left: 7px;
  transform: rotate(-90deg);
}
.dropdown__toggle.dropdown__toggle--opened {
  background-color: $ex-bg-navbar-menu;
}
.dropdown__toggle.dropdown__toggle--opened:after {
  transform: rotate(0);
}
.dropdown-menu-item__img {
  max-width: 270px;
  height: auto;
  &.dropdown-menu-item__img--error {
    width: 270px;
    height: 100px;
  }
}
</style>
