import { combineEpics } from '@landing/landing-mobile/utils'
import appEpics from './app'
import toastEpics from './toast'
import cartEpics from './cart'
import orderEpics from './order'

export default combineEpics(
  ...appEpics,
  ...toastEpics,
  ...cartEpics,
  ...orderEpics
)
