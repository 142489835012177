<template>
  <div class="fixed-toolbar">
    <a v-if="hasFb"
       class="fixed-toolbar__btn fixed-toolbar__fb-btn"
       :href="fbUrl"
       aria-label="洽詢臉書客服">
      <fb-icon-svg />
    </a>
    <a v-if="hasLine"
       class="fixed-toolbar__btn fixed-toolbar__line-btn"
       :href="lineUrl"
       aria-label="洽詢Line客服">
      <line-icon-svg />
    </a>
    <a v-if="hasWhatsapp"
       key="whatsappBtn"
       class="fixed-toolbar__btn fixed-toolbar__whatsapp-btn"
       aria-label="洽詢 WhatsApp 客服"
       :href="whatsappUrl">
      <whatsapp-icon-svg />
    </a>
    <button class="fixed-toolbar__btn fixed-toolbar__cart-btn"
            data-cy="cart-btn"
            aria-label="購物車"
            @click="$emit('click-cart')">
      <pulse-loader :loading="isLoadingCart" color="#fff" :size="8" />
      <cart-icon-svg v-show="! isLoadingCart" />
      <span v-show="(! isLoadingCart) && (countInCart > 0)"
            data-cy="cart-count"
            :class="{ 'fixed-toolbar__count-in-cart--long': countInCart >= 10 }"
            class="fixed-toolbar__count-in-cart">{{ cartNum }}</span>
      <cart-tooltip :message="tooltipMessage" />
    </button>
    <button v-show="isBackToTopBtnVisible"
            class="fixed-toolbar__btn fixed-toolbar__back-to-top-btn"
            aria-label="回到最上方" @click="scrollToTop">
      <up-icon-svg />
    </button>
  </div>
</template>

<script>
import CartIconSvg from '@landing/landing-common/components/CartIconSvg'
import FbIconSvg from '@landing/landing-common/components/FbIconSvg'
import LineIconSvg from '@landing/landing-common/components/LineIconSvg'
import WhatsappIconSvg from '@landing/landing-common/components/WhatsappIconSvg'
import contactMixin from '@landing/landing-common/mixins/contactMixin'
import domMixin from '@landing/common/mixins/domMixin'
import { PulseLoader, animateScrollTo, throttle } from '@landing/landing-mobile/utils'
import CartTooltip from './CartTooltip'
import UpIconSvg from './UpIconSvg'

export default {
  name: 'FixedToolbar',
  components: {
    PulseLoader,
    FbIconSvg,
    LineIconSvg,
    CartIconSvg,
    CartTooltip,
    UpIconSvg,
    WhatsappIconSvg
  },
  mixins: [domMixin, contactMixin],
  props: {
    page: Object,
    countInCart: {
      type: Number,
      default: 0
    },
    isLoadingCart: Boolean,
    tooltipMessage: String,
    fbVisible: {
      type: Boolean,
      default: true
    },
    lineVisible: {
      type: Boolean,
      default: true
    },
    whatsappVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isBackToTopBtnVisible: false
    }
  },
  computed: {
    cartNum() {
      if (this.countInCart > 99) {
        return '99+'
      }
      return String(this.countInCart)
    },
    hasFb() {
      const { fanPage } = this.page
      return this.fbVisible && fanPage && fanPage.fixed
    },
    hasLine() {
      const { line } = this.page
      return this.lineVisible && line && line.fixed
    },
    hasWhatsapp() {
      const { whatsapp } = this.page
      return this.whatsappVisible && whatsapp && whatsapp.fixed
    }
  },
  mounted() {
    this.on(window, 'scroll', throttle(this.handleScroll, 300))
  },
  methods: {
    scrollToTop() {
      animateScrollTo(0, { speed: 30 })
    },
    handleScroll() {
      const doc = document.documentElement
      const scrollTop = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0)
      this.isBackToTopBtnVisible = (scrollTop > (window.innerHeight * 4))
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed-toolbar {
  position: fixed;
  bottom: 76px;
  right: 7px;
  height: 256px;
  z-index: 2;
}
.fixed-toolbar__btn {
  @include size(48px);
  margin-bottom: 14px;
  padding: 0;
  display: block;
  border-radius: 50%;
  background-color: transparent;
  img {
    display: block;
  }
  &:active img {
    filter: brightness(0.5);
  }
}
.fixed-toolbar__fb-btn {
  background-color: $blue8;
}
.fixed-toolbar__line-btn {
  background-color: $green1;
}
.fixed-toolbar__cart-btn {
  background-color: $red1;
  position: relative;
  @include flex-center;
}
.fixed-toolbar__back-to-top-btn {
  background-color: $white1;
}
.fixed-toolbar__btn.fixed-toolbar__whatsapp-btn {
  @include size(45px);
  border: 0;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, .12);
  margin-left: auto;
  margin-right: auto;
}
.fixed-toolbar__fb-btn,
.fixed-toolbar__line-btn,
.fixed-toolbar__cart-btn,
.fixed-toolbar__back-to-top-btn {
  border: 0;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, .12);
}
$size-count-in-cart: 20px;
.fixed-toolbar__count-in-cart.fixed-toolbar__count-in-cart--long {
  width: 30px;
  height: initial;
  line-height: initial;
  border-radius: 8px;
}
.fixed-toolbar__count-in-cart {
  font-size: 13px;
  font-weight: 900;
  color: $brand-primary;
  background-color: #fff;
  border: 1px solid $brand-primary;
  border-radius: 50%;
  @include size($size-count-in-cart);
  line-height: $size-count-in-cart;
  display: block;
  position: absolute;
  top: -7px;
  right: 0;
}
</style>
