<template>
  <div class="price-list">
    <discount-lv-list v-if="page.discountLevels.length > 0"
                      class="price-list__discount-lv-list"
                      :items="page.discountLevels"
                      :layout-discount="$consts.layoutDiscount"
                      :total-order-amount-with-coupon="cart.totalOrderAmountWithCoupon" />
    <table>
      <tbody>
        <tr v-if="cart.orderAmount !== cart.totalOrderAmountWithDiscount">
          <td>總計</td>
          <td class="price-list__value">{{ dollar(cart.orderAmount) }}</td>
        </tr>
        <tr>
          <td>運費</td>
          <td class="price-list__value"
              :class="{ 'text-free': noShippingFee }">
            {{ shippingFee }}
          </td>
        </tr>
        <tr v-if="(couponDiscount < 0)">
          <td>折價卷 <strong v-if="cart.couponCode">{{ cart.couponCode }}</strong></td>
          <td class="price-list__value text-free">{{ dollar(couponDiscount) }}</td>
        </tr>
        <tr v-if="(paymentDiscount < 0)">
          <td>滿額優惠</td>
          <td class="price-list__value text-free">{{ dollar(paymentDiscount) }}</td>
        </tr>
        <tr>
          <td>{{ totalPriceLabel }}</td>
          <td class="price-list__final-price">{{ currency(cart.totalOrderAmountWithDiscount) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import DiscountLvList from '@landing/landing-common/components/DiscountLvList'
import Price from '@landing/landing-common/models/Price'
import currency from '@landing/landing-common/utils/currency'
import dollar from '@landing/landing-common/utils/dollar'

export default {
  name: 'PriceList',
  components: {
    DiscountLvList
  },
  props: {
    cart: Object,
    page: Object
  },
  computed: {
    couponDiscount() {
      return Price.getCouponDiscount(this.cart)
    },
    noShippingFee() {
      return (this.cart.shippingFee === 0)
    },
    paymentDiscount() {
      return Price.getPaymentDiscount(this.cart)
    },
    shippingFee() {
      if (this.noShippingFee) {
        return '免運費'
      }
      return dollar(this.cart.shippingFee)
    },
    totalPriceLabel() {
      if (Price.getDiscount(this.cart) < 0) {
        return '折扣後總金額'
      }
      return '總金額'
    }
  },
  methods: {
    currency,
    dollar
  }
}
</script>

<style lang="scss">
.price-list__discount-lv-list {
  width: 100%;
  .discount-lv-title__tag {
    display: block;
    margin-left: 0;
    margin-top: .4em;
    margin-bottom: .2em;
    width: fit-content;
  }
  .txt-danger {
    color: $red2;
  }
}
</style>

<style lang="scss" scoped>
.price-list {
  color: $black5;
  @include shadow();
  background-color: $white1;
  margin: 14px;
  padding: 14px;
  table {
    width: 100%;
    td {
      padding: 4px 0;
    }
  }
}
.price-list__value {
  text-align: right;
}
.price-list__final-price {
  text-align: right;
  color: $red4;
  font-size: 1.2em;
  font-weight: 900;
}
</style>
