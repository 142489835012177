<template>
  <div id="landing-app">
    <router-view />
    <top-toast :visible="! online" />
  </div>
</template>

<script>
import TopToast from '@landing/landing-mobile/components/TopToast'
import domMixin from '@landing/common/mixins/domMixin'
import networkStatusMixin from '@landing/landing-mobile/mixins/networkStatusMixin'
import { clearStore } from '@landing/landing-mobile/redux/reducers'
import { setCart, setPageToCart } from '@landing/landing-mobile/redux/reducers/cart'
import { setOrder } from '@landing/landing-mobile/redux/reducers/order'
import { setPage, setSite } from '@landing/landing-mobile/redux/reducers/page'
import Tracker from '@landing/landing-common/models/Tracker'
import Vue from '@landing/landing-mobile/consts/vue'
import setConst from '@landing-mobile/utils/setConst'

export default {
  name: 'App',
  components: {
    TopToast
  },
  mixins: [domMixin, networkStatusMixin],
  props: {
    site: Object,
    page: Object,
    cart: Object,
    order: Object,
    url: String
  },
  data() {
    return this.mapState({
      online: 'app.online',
      pageData: 'page.page'
    })
  },
  beforeCreate() {
    this.mapDispatch({
      setPage,
      setOrder,
      setPageToCart,
      setCart,
      setSite,
      clearStore
    })
    this.store.unsubscribeAll()
    this.clearStore()
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      Vue.prototype.$url = this.url
      const { page } = this
      this.setSite(this.site)
      this.setPage(page)
      this.setPageToCart(page)
      this.setCart(this.cart)
      this.setOrder(this.order)

      if (this.isClient) {
        Vue.prototype.$tracker = new Tracker({
          page: this.pageData,
          site: this.site,
          cart: this.cart
        })
      }
      setConst(page)
    }
  }
}
</script>
