<template>
  <div class="product-list-item card" data-cy="product-item">
    <template v-if="mode === 'grid'">
      <app-image class="product-list-item__img"
                 :src="data.image"
                 :alt="data.title"
                 lazy />
    </template>
    <template v-else-if="(mode === 'list') && data.image">
      <app-image class="product-list-item__img"
                 :src="data.image"
                 :alt="data.title"
                 lazy />
    </template>

    <div class="product-list-item__content">
      <div>
        <div class="product-list-item__title">{{ data.title }}</div>
        <preorder-info v-if="data.isPreorder"
                       class="product-list-item__preorder-info"
                       :item="data"
                       :mode="mode" />
        <div v-if="data.strengthening"
             class="product-list-item__strong">
          {{ data.strengthening }}
        </div>
        <div v-if="data.additional"
             class="product-list-item__additional"
             v-text="data.additional" />
        <div class="product-list-item__money">
          <div v-if="isOriginalPriceVisible"
               class="product-list-item__original-price">
            原價 <deleted-price>{{ dollar(data.originalPrice) }}</deleted-price>
          </div>
          <div v-if="textOnSale" class="product-list-item__price">{{ textOnSale }}</div>
        </div>
      </div>
      <button v-if="isAdditionalPurchase" ref="btn" class="btn-secondary fw"
              @click="$emit('secondary-btn-click', data)">
        加購
      </button>
      <button v-else
              ref="btn"
              class="btn-primary fw"
              :disabled="outOfStock"
              @click="$emit('primary-btn-click', data)">
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import PreorderInfo from '@landing/landing-common/components/PreorderInfo'
import dollar from '@landing/landing-common/utils/dollar'
import { comma } from '@landing/landing-common/utils'
import AppImage from './AppImage'
import DeletedPrice from './DeletedPrice'
import discountText from '@landing-mobile/utils/discountText'
import currency from '@common/utils/currency'

export default {
  name: 'ProductListItem',
  components: {
    AppImage,
    DeletedPrice,
    PreorderInfo
  },
  props: {
    data: Object,
    isAdditionalPurchase: Boolean,
    mode: {
      type: String,
      default: 'list'
    }
  },
  computed: {
    isOriginalPriceVisible() {
      if (this.isAdditionalPurchase) {
        return false
      }
      const { originalPrice } = this.data
      return Number.isInteger(originalPrice) && (originalPrice > 0)
    },
    textOnSale() {
      const { textOnSale, price, promoType } = this.data

      if (promoType === 'discounts') {
        return discountText(this.data.promoDetails.rate)
      }
      if (this.isAdditionalPurchase) {
        return `加購價: ${dollar(price)}`
      }
      if (textOnSale) {
        return textOnSale.replace(/%s/g, comma(price))
          .replace(/%c/g, currency(this.$consts.currency))
      }
      return dollar(price)
    },
    outOfStock() {
      return (this.data.status === 'out_of_stock')
    },
    buttonText() {
      if (this.outOfStock) {
        return '缺貨中'
      }
      return this.data.textButton || '點此選購'
    }
  },
  methods: {
    dollar
  }
}
</script>

<style lang="scss">
.product-list-item__preorder-info.preorder-info {
  margin-top: .7em;
  margin-bottom: .4em;
}
</style>

<style lang="scss" scoped>
.product-list-item {
  display: flex;
  flex-direction: column;
}
.product-list-item__img {
  display: block;
  width: 100%;
  height: auto;
}
.product-list-item__title {
  font-weight: 400;
  font-size: 1.2em;
  margin-bottom: 5px;
}
.product-list-item__strong {
  font-size: 1em;
  line-height: 1.6em;
  color: #4b80b6;
}
.product-list-item__additional {
  white-space: pre-wrap;
  font-size: .675em;
  line-height: 1.6em;
  color: $black5;
}
.product-list-item__original-price {
  color: $black5;
  font-size: 0.857em;
}
.product-list-item__price {
  color: $red4;
  font-weight: 500;
  font-size: 1em;
}
.product-list-item__content {
  padding: 10px 14px 14px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product-list-item__money {
  margin-top: 9px;
  margin-bottom: 20px;
}
</style>
