import { showNavbarMenu, hideNavbarMenu, setSiteNavHeight } from '../redux/reducers/app'
import { setPage } from '../redux/reducers/page'
import { getCartData, setPageToCart } from '../redux/reducers/cart'
import { TYPE_NAVBAR_EMPTY } from '@common/consts/component'
import http from '../plugins/http'
import Vue from '../consts/vue'
import Tracker from '@landing/landing-common/models/Tracker'
import { get, toPixel } from '../utils'
import processIgEmbeds from '@landing/landing-common/utils/processIgEmbeds'
import setConst from '@landing-mobile/utils/setConst'

let loadedPage = null

const getPage = async to => {
  if (to.name === 'SalesPage') {
    const alias = to.path.slice(1)
    const res = await http.get(`/${alias}.json`)
    return res.data
  }
  if (to.name === 'StaticPage') {
    const [, id] = to.path.match(/^\/contents\/(\w+)$/)
    const res = await http.get(`/contents/${id}.json`)
    return res.data
  }
}

export default {
  data() {
    return this.mapState({
      page: 'page.page',
      isNavbarMenuVisible: 'app.isNavbarMenuVisible',
      siteNavHeight: 'app.siteNavHeight'
    })
  },
  computed: {
    hasSiteNav() {
      return get(this, 'page.site.navbar.enabled', false)
    },
    hasNavbar() {
      const type = get(this, 'page.content.navbar.type')
      return type && (type !== TYPE_NAVBAR_EMPTY)
    },
    paddingTop() {
      return toPixel(this.siteNavHeight)
    }
  },
  methods: {
    _setPage(page) {
      this.setPage(page)
      this.setPageToCart(page)
      Vue.prototype.$tracker = new Tracker({ page })
      setConst(page)
      document.title = page.title
    },
    setNavHeight(el) {
      this.setSiteNavHeight(el.offsetHeight)
    }
  },
  beforeCreate() {
    this.mapDispatch({
      getCartData,
      hideNavbarMenu,
      setPage,
      setPageToCart,
      setSiteNavHeight,
      showNavbarMenu
    })
  },
  created() {
    // this hook needs to call before cartMixin's created
    if (this.isClient && loadedPage) {
      this._setPage(loadedPage)
      loadedPage = null
    }
  },
  async beforeRouteEnter(to, from, next) {
    if (from.name === null) {
      return next()
    }
    if (typeof window === 'undefined') {
      return next()
    }
    try {
      loadedPage = await getPage(to)
    }
    catch (err) {
      location.href = to.path
      return
    }
    next()
  },
  async beforeRouteUpdate (to, from, next) {
    try {
      const page = await getPage(to)
      this._setPage(page)
    }
    catch (err) {
      location.href = to.path
      return
    }
    if (to.name === 'SalesPage') {
      this.getCartData()
    }
    next()

    if (['SalesPage', 'StaticPage'].includes(to.name)) {
      this.$nextTick(() => {
        processIgEmbeds()
        this.$tracker.pageView()

        // 切到靜態頁要重設 scroll event
        if (to.name === 'StaticPage') {
          this.bindScrollEvent()
        }
        // 切到銷售頁要重設 viewContent watchers
        if (to.name === 'SalesPage') {
          const { productList } = this.$refs
          productList && productList.initViewContent()
        }
      })
    }
  }
}
