<template>
  <button class="sort-btn btn-icon"
          data-cy="sort-btn"
          :aria-label="ariaLabel"
          @click="$emit('click')" />
</template>

<script>
export default {
  name: 'SortBtn',
  props: {
    ariaLabel: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
$size-arrow: 7px;

.sort-btn {
  overflow: hidden;
  width: 28px;
  height: 36px;
  position: relative;
  display: inline-block;
  &:before,
  &:after {
    @include size($size-arrow);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: absolute;
    content: '';
  }
  &:before {
    top: 0;
    border-top: $size-arrow solid transparent;
    border-left: $size-arrow solid transparent;
    border-right: $size-arrow solid transparent;
    border-bottom: $size-arrow solid $black8;
  }
  &:after {
    bottom: 0;
    border-bottom: $size-arrow solid transparent;
    border-left: $size-arrow solid transparent;
    border-right: $size-arrow solid transparent;
    border-top: $size-arrow solid $black8;
  }
}
</style>
