<template>
  <vertical-menu :is-visible="isVisible"
                 :heading="heading"
                 :show-close-btn="false">
    <div v-if="item" class="free-product-set-menu">
      <div class="alert alert-info">
        請從以下贈品任選 <strong>{{ item.qty }}</strong> 個
      </div>
      <form class="free-product-set-menu__form form" @submit.prevent="handleSubmit">
        <div class="free-product-set-menu__items">
          <label v-for="freeProduct in item.freeProducts"
                 :key="freeProduct.id"
                 data-cy="free-product-item">
            <span>{{ freeProduct.title }}</span>
            <select v-model="freeProductsMap[freeProduct.id]"
                    data-cy="free-product-select"
                    class="select">
              <option disabled value="">選擇贈品數量</option>
              <option v-for="o in getSelectOptions(freeProduct)"
                      :key="o.value"
                      :value="o.value">{{ o.text }}</option>
            </select>
          </label>
        </div>
        <button class="btn-secondary fw" data-cy="free-product-submit">加到購物車</button>
      </form>
    </div>
  </vertical-menu>
</template>

<script>
import { range } from '@landing/landing-mobile/utils'
import VerticalMenu from './VerticalMenu'

export default {
  name: 'FreeProductSetMenu',
  components: {
    VerticalMenu
  },
  props: {
    isVisible: Boolean,
    item: Object
  },
  data() {
    return {
      freeProductsMap: this.item ? this.getFreeProductsMap(this.item) : []
    }
  },
  computed: {
    heading() {
      return '選擇贈品'
    }
  },
  watch: {
    item(value) {
      if (value) {
        this.freeProductsMap = this.getFreeProductsMap(value)
      }
    }
  },
  methods: {
    getFreeProductsMap(item) {
      return item.freeProducts.reduce((obj, p) => {
        obj[p.id] = ''
        return obj
      }, {})
    },
    getSelectOptions(freeProduct) {
      return range(0, this.item.qty + 1)
        .map(index => ({
          text: `${index} ${freeProduct.unitName}`,
          value: index
        }))
    },
    handleSubmit() {
      const { freeProductsMap } = this
      const data = Object.keys(freeProductsMap)
        .filter(prop => {
          const value = freeProductsMap[prop]
          return Number.isInteger(value) && (value > 0)
        })
        .reduce((obj, prop) => {
          const value = freeProductsMap[prop]
          obj[prop] = value
          return obj
        }, {})

      this.$emit('submit', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.free-product-set-menu {
  padding: 0 14px 14px;
}
.free-product-set-menu__form {
  label {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}
.btn-secondary {
  margin-top: 48px;
}
.free-product-set-menu__items {
  max-height: 400px;
  overflow: auto;
}
</style>
