<template>
  <button class="btn"
          :class="{ 'btn--checked': checked }"
          @click="$emit('click')">
    <fa-icon v-if="checked" class="btn__checkmark" icon="check" />
    <slot />
  </button>
</template>

<script>
export default {
  name: 'CheckmarkBtn',
  props: {
    checked: Boolean
  }
}
</script>

<style lang="scss" scoped>
.btn--checked {
  border-color: $blue5;
  color: $blue5;
}
.btn__checkmark {
  margin-right: 4px;
}
</style>
