<template>
  <li class="promo-list-item" data-cy="selected-promo-item">
    <div class="promo-list-item__content">
      <div class="promo-list-item__image-box">
        <app-image v-if="item.image" :src="item.image" alt="" />
      </div>
      <div>
        <div class="promo-list-item__title">{{ item.title }}</div>
        <button class="btn-icon btn-minus" data-cy="minus-btn" @click.stop.prevent="$emit('minus', item)">
          <fa-icon icon="minus" />
        </button>
        <span class="promo-list-item__qty">{{ `${item.qty} ${item.unitName}` }}</span>
        <span v-if="item.spec" class="promo-list-item__spec">{{ item.spec }}</span>
        <button class="btn-icon btn-plus" data-cy="plus-btn" @click.stop.prevent="$emit('plus', item)">
          <fa-icon icon="plus" />
        </button>
      </div>
    </div>
    <close-btn v-if="isShowMode"
               class="promo-list-item__close-btn"
               data-cy="confirm-remove-btn"
               @click="toEditMode" />
    <div v-if="isEditMode" class="promo-list-item__cloak">
      <div>
        <button class="promo-list-item__btn-cancel"
                @click.stop.prevent="toShowMode">
          取消
        </button>
        <button class="promo-list-item__btn-remove"
                data-cy="remove-btn"
                @click.stop.prevent="$emit('remove', item)">
          刪除
        </button>
      </div>
    </div>
  </li>
</template>

<script>
import AppImage from './AppImage'
import CloseBtn from './CloseBtn'

export default {
  name: 'OrderItemMenuUserPromoListItem',
  components: {
    AppImage,
    CloseBtn
  },
  props: {
    item: Object
  },
  data() {
    return {
      mode: 'show'
    }
  },
  computed: {
    isShowMode() {
      return this.mode === 'show'
    },
    isEditMode() {
      return this.mode === 'edit'
    }
  },
  methods: {
    toShowMode() {
      this.mode = 'show'
    },
    toEditMode() {
      this.mode = 'edit'
    }
  }
}
</script>

<style lang="scss" scoped>
.promo-list-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
}
.promo-list-item__qty {
  white-space: nowrap;
  color: $blue6;
  margin-right: 4px;
}
.promo-list-item__spec {
  white-space: nowrap;
  color: $purple1;
  margin-right: 4px;
}
.promo-list-item__close-btn {
  @include size(18px);
}
.promo-list-item__cloak {
  color: $white1;
  background-color: rgba(0, 0, 0, .7);
  @include absolute-center;
  @include flex-center;
}
.promo-list-item__btn-remove,
.promo-list-item__btn-cancel {
  padding: 3px 18px;
  background-color: transparent;
  border: 1px solid $white1;
  border-radius: 2px;
  margin: 0 14px;
}
.promo-list-item__btn-remove {
  background-color: $white1;
  color: $black0;
}
.promo-list-item__image-box {
  background: $gray7;
  @include size(50px);
  > img {
    @include size(50px);
  }
  margin-right: 14px;
}
.promo-list-item__content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.btn-icon.btn-minus,
.btn-icon.btn-plus {
  @include size(25px);
  background-color: $white1;
  padding: 7px;
  font-size: 10px;
  border-radius: 100%;
}
.btn-icon.btn-minus {
  margin-right: 7px;
}
.btn-icon.btn-plus {
  margin-left: 7px;
  margin-right: 7px;
}
.promo-list-item__title {
  margin-bottom: 7px;
  margin-right: 14px;
}
</style>
