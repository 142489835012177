<template>
  <div class="order-item-menu-product-list scrollable">
    <div v-for="product in items"
         :key="product.id"
         data-cy="promo-modal-item"
         class="order-item-menu-product-list__item"
         @click="$emit('item-click', product)">
      <template v-if="hasImageCol">
        <app-image v-if="product.image"
                   class="order-item-menu-product-list__item-img"
                   :src="product.image"
                   :alt="product.title" />
        <div v-else class="order-item-menu-product-list__img-placeholder" />
      </template>
      <div class="order-item-menu-product-list__item-title">
        <div v-text="product.title" />
        <preorder-info v-if="product.isPreorder"
                       class="order-item-menu-product-list__preorder-info"
                       :item="product"
                       mode="grid" />
        <div v-if="productPriceVisible"
             class="order-item-menu-product-list__original-price"
             v-text="`售價 ${dollar(product.price)}`" />
        <div v-if="product.strengthening" class="txt-strengthening" v-text="product.strengthening" />
        <div v-if="product.additional" class="txt-additional" v-text="product.additional" />
      </div>
      <button class="btn-icon order-item-menu-product-list__plus-btn">
        <fa-icon icon="plus" />
      </button>
    </div>
    <div class="order-item-menu-product-list__empty-block" />
  </div>
</template>

<script>
import AppImage from './AppImage'
import PreorderInfo from '@landing/landing-common/components/PreorderInfo'
import dollar from '@landing/landing-common/utils/dollar'

export default {
  name: 'OrderItemMenuProductList',
  components: {
    AppImage,
    PreorderInfo
  },
  props: {
    items: Array,
    page: Object
  },
  computed: {
    productPriceVisible() {
      const { page } = this
      return page && (page.layout.promoProductPrice === 'visible')
    },
    hasImageCol() {
      return this.items.some(item => item.image)
    }
  },
  methods: {
    dollar
  }
}
</script>

<style lang="scss" scoped>
// Android Firefox ignores padding with with overflow:auto / scroll
// https://bugzilla.mozilla.org/show_bug.cgi?id=748518
.order-item-menu-product-list__empty-block {
  height: 60vh;
}
.order-item-menu-product-list__item {
  padding-right: 21px;
  margin-bottom: 7px;
  transition: .2s all;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 70px;
}
.order-item-menu-product-list__item-title {
  flex-grow: 1;
  flex-shrink: 1;
  color: $black5;
  padding: 0 12px;
}
.order-item-menu-product-list__item-img {
  width: 70px;
  height: auto;
}
.order-item-menu-product-list__img-placeholder {
  width: 70px;
  height: 70px;
  flex-shrink: 0;
}
.order-item-menu-product-list__plus-btn {
  background-color: $gray12;
  padding: 7px 10px;
  border-radius: 4px;
  color: $black7;
}
.order-item-menu-product-list__original-price {
  font-size: .875em;
  color: #555;
  margin-top: .4em;
}
.txt-strengthening,
.txt-additional {
  font-size: .7em;
  margin-top: .2em;
}
.txt-additional {
  white-space: pre-wrap;
}
.order-item-menu-product-list__preorder-info {
  margin-top: .5em;
  font-size: .875em;
}
</style>
