<template>
  <transition-group v-if="mode === 'list'"
                    name="item-group"
                    class="product-list--list-mode"
                    tag="div">
    <div v-for="item in items" :key="item.key">
      <app-image v-if="item.banner && item.banner.mobile"
                 class="product-list-item-group__banner"
                 :src="item.banner.mobile"
                 lazy
                 alt="商品banner" />
      <product-list-item class="product-list__item"
                         :data="item"
                         :is-additional-purchase="item.isAdditionalPurchase"
                         :mode="mode"
                         @primary-btn-click="$emit('primary-btn-click', item)"
                         @secondary-btn-click="$emit('secondary-btn-click', item)" />
    </div>
  </transition-group>
  <transition-group v-else-if="mode === 'grid'" name="item-group"
                    class="product-list--grid-mode">
    <product-list-item v-for="item in items"
                       :key="item.key"
                       class="product-list__item"
                       :data="item"
                       :is-additional-purchase="item.isAdditionalPurchase"
                       :mode="mode"
                       @primary-btn-click="$emit('primary-btn-click', item)"
                       @secondary-btn-click="$emit('secondary-btn-click', item)" />
  </transition-group>
</template>

<script>
import AppImage from './AppImage'
import ProductListItem from './ProductListItem'

export default {
  name: 'ProductListItemGroup',
  components: {
    AppImage,
    ProductListItem
  },
  props: {
    items: Array,
    mode: {
      type: String,
      default: 'list'
    }
  }
}
</script>

<style lang="scss" scoped>
.product-list__item {
  margin-bottom: 14px;
}
.product-list--list-mode {
  margin-left: 14px;
  margin-right: 14px;
}
.product-list--grid-mode {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  .product-list__item {
    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: calc(50% - 14px);
    margin-bottom: 14px;
    margin-left: 7px;
    margin-right: 7px;
  }
}
.product-list-item-group__banner {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}
</style>
