<template>
  <transition name="vertical-slide">
    <div v-if="isVisible" ref="modal" class="cart-modal">
      <title-bar title="購物車"
                 :logo-url="site && site.logoUrl.mobile"
                 can-close
                 @close="$emit('close')" />
      <div class="cart-modal__content"
           :class="{ 'cart-modal__content--center': isCenterBackground }">
        <cart-modal-error v-if="isCartLoadingFailed" :error="errorLoadingCart" @reload="$emit('reload')" />
        <cart-modal-loading v-else-if="isLoadingCart" @close="$emit('close')" />
        <cart-modal-empty v-else-if="isCartEmpty"
                          @close="$emit('close')" />
        <div v-else-if="hasCartItems" class="cart-modal__list scrollable">
          <cart-modal-list :items="cartItems"
                           @remove-item="handleRemoveItem" />
          <price-list :cart="cart" :page="page" />
          <coupon-form v-if="page.withCoupon" />
          <div class="cart-modal__empty-block" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CartModalEmpty from '@landing/landing-mobile/components/CartModalEmpty'
import CartModalError from '@landing/landing-mobile/components/CartModalError'
import CartModalList from '@landing/landing-mobile/components/CartModalList'
import CartModalLoading from '@landing/landing-mobile/components/CartModalLoading'
import PriceList from '@landing/landing-mobile/components/PriceList'
import TitleBar from '@landing/landing-mobile/components/TitleBar'
import getScrollMixin from '@landing/common/mixins/bodyScrollMixin'
import { addToastItem } from '@landing/landing-mobile/redux/reducers/toast'
import { removeOrderProduct, addOrderProduct } from '@landing/landing-mobile/redux/reducers/cart'
import HumanError from '@landing/landing-common/models/HumanError'
import CouponForm from './CouponForm'

const bodyScrollMixin  = getScrollMixin('isVisible')

export default {
  name: 'CartModal',
  components: {
    CouponForm,
    CartModalLoading,
    CartModalEmpty,
    CartModalError,
    CartModalList,
    TitleBar,
    PriceList
  },
  mixins: [bodyScrollMixin],
  props: {
    isVisible: Boolean
  },
  data() {
    return {
      couponErrorMessage: '',
      ...this.mapState({
        page: 'page.page',
        site: 'page.page.site',
        isCartLoaded: 'cart.isCartLoaded',
        isLoadingCart: 'cart.isLoadingCart',
        isCartLoadingFailed: 'cart.isCartLoadingFailed',
        errorLoadingCart: 'cart.errorLoadingCart',
        cart: 'cart.cart',
        cartItems: 'cart.cart.items'
      })
    }
  },
  computed: {
    isCartEmpty() {
      return this.isCartLoaded && (this.cartItems.length === 0)
    },
    isCenterBackground() {
      return this.isLoadingCart || this.isCartLoadingFailed || this.isCartEmpty
    },
    hasCartItems() {
      return this.isCartLoaded && (this.cartItems.length > 0)
    }
  },
  beforeCreate() {
    this.mapDispatch({ removeOrderProduct, addOrderProduct, addToastItem })
  },
  methods: {
    getRemoveSuccessMessage(item) {
      if (item.type === 'product') {
        return `已刪除 ${item.qty} ${item.unitName}${item.title}`
      }
      return `已刪除${item.title}`
    },
    handleRemoveItem(item) {
      if (item.isLoading) {
        return
      }
      item.isLoading = true
      this.removeOrderProduct(item.id)
        .then(() => this.addToastItem({ message: this.getRemoveSuccessMessage(item) }))
        .catch(err => {
          const message = HumanError.getMessage(err)
          if (message) {
            return this.addToastItem({ message })
          }
          this.addToastItem({ message: `${item.title}刪除失敗` })
        })
        .finally(() => {
          item.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-modal {
  @include fixed-center;
  background-color: $gray3;
  z-index: 4;
}
.cart-modal__content {
  height: 100%;
}
.cart-modal__empty-block {
  height: 300px;
}
.cart-modal__content--center {
  padding: 0;
  @include absolute-center;
  @include flex-center;
}
.vertical-slide-enter-active {
  transition-duration: .16s
}
.vertical-slide-leave-active {
  transition-duration: .16s
}
.cart-modal__list {
  max-height: 100%;
  padding-bottom: 100px;
}
</style>
