<template>
  <transition name="vertical-slide" appear>
    <div class="app-toast">
      <transition-group name="horizontal-slide" tag="div" appear>
        <div v-for="item in items" :key="item.id">
          <div class="app-toast__item-content clearfix"
               @click="$emit('remove-toast-item', item.id)">
            <div class="app-toast__item-message">{{ item.message }}</div>
            <button v-if="item.btnText" class="app-toast__item-btn"
                    @click.stop.prevent="item.btnFunc">
              {{ item.btnText }}
            </button>
          </div>
        </div>
      </transition-group>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AppToast',
  props: {
    items: Array
  }
}
</script>

<style lang="scss" scoped>
.app-toast {
  width: 100%;
  z-index: 4;
  position: fixed;
  bottom: 86px;
}
.app-toast__item {
}
.app-toast__item-content {
  display: inline-block;
  margin: 0 14px 14px;
  border-radius: 4px;
  padding: 14px;
  background-color: $black5;
  color: $white1;
}
.app-toast__item-btn {
  padding: 0;
  margin-top: 7px;
  border: 0;
  background-color: transparent;
  display: block;
  color: $orange2;
  float: right;
}
.horizontal-slide-enter,
.horizontal-slide-leave-to {
  opacity: 0;
}
</style>
