// discountHintMixin requires domMixin
import { throttle } from '@landing-desktop/utils'

export default {
  data() {
    return {
      discountHintVisible: false
    }
  },
  mounted() {
    this.on(window, 'scroll', throttle(() => {
      const list = this.$refs.discountLvList
      if (! list) {
        return
      }
      const dom = list.$el
      const { bottom } = dom.getBoundingClientRect()
      this.discountHintVisible = bottom < this.siteNavHeight
    }, 100))
  },
  computed: {
    applicableDiscountItem() {
      const amount = this.cartData.totalOrderAmountWithCoupon
      if (typeof amount !== 'undefined') {
        return this.page.discountLevels.find(item => item.amount > amount)
      }
      return null
    }
  }
}
