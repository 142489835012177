<template>
  <div class="order-item-menu-product">
    <app-image v-if="item.hasImg"
               class="order-item-menu-product__img"
               :src="productImgSrc"
               :alt="item.title" />
    <preorder-info v-if="item.isPreorder"
                   class="order-item-menu-product__preorder-info"
                   :item="item"
                   mode="list" />
    <product-desc-links class="order-item-menu-product__product-desc-links"
                        :item="item"
                        :active="activeProductKey"
                        @click="setProductImgRow" />
    <form class="order-item-menu-product__form form" @submit.prevent="handleSubmit">
      <label v-if="item.hasSpec">
        <span>選擇規格</span>
        <select v-model="selectedSpecId" class="select" data-cy="spec-select">
          <option v-for="o in item.specOptions"
                  :key="o.value"
                  :value="o.value"
                  :disabled="o.status === 'out_of_stock'">{{ o.text }}</option>
        </select>
      </label>
      <label>
        <span>選擇數量</span>
        <select v-model="selectedQuantity" class="select" data-cy="qty-select">
          <option v-for="o in item.quantityOptions"
                  :key="o.value"
                  :value="o.value">{{ o.text }}</option>
        </select>
      </label>
      <button class="btn-primary fw" type="submit">加到購物車</button>
    </form>
  </div>
</template>

<script>
import AppImage from './AppImage'
import PreorderInfo from '@landing/landing-common/components/PreorderInfo'
import ProductDescLinks from '@landing-common/components/ProductDescLinks'
import productImgMixin from '@landing-common/mixins/productImgMixin'

export default {
  name: 'OrderItemMenuProduct',
  components: {
    AppImage,
    PreorderInfo,
    ProductDescLinks
  },
  mixins: [productImgMixin],
  props: {
    item: Object
  },
  data() {
    const { defaultSelectedSpecId, defaultSelectedQuantity } = this.item
    return {
      selectedSpecId: defaultSelectedSpecId,
      selectedQuantity: defaultSelectedQuantity
    }
  },
  methods: {
    handleSubmit() {
      const { id, title, image, price, isAdditionalPurchase } = this.item
      const { selectedQuantity, selectedSpecId } = this
      const data = {
        id,
        type: 'product',
        title,
        image,
        qty: selectedQuantity,
        price,
        isAdditionalPurchase
      }
      if (this.item.hasSpec) {
        data.specId = selectedSpecId
      }
      this.$emit('submit', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.order-item-menu-product {
  padding: 0 14px 14px;
}
.order-item-menu-product__img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 40vh;
  height: auto;
}
.order-item-menu-product__form {
  label {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}
.btn-primary {
  margin-top: 40px;
}
.order-item-menu-product__preorder-info {
  margin-top: 1em;
  margin-bottom: .2em;
}
.order-item-menu-product__product-desc-links {
  margin-top: 1em;
}
</style>
