<template>
  <div class="order-item-menu-promo">
    <div v-if="(item.promoType === 'discounts' && discount)"
         class="alert alert-info">
      請混搭選擇 <strong>{{ quantity }}</strong> 個商品即可享有 <strong>{{ discount }}</strong> 優惠
      <template v-if="hasDiscountRange">，<br>如需加購請選擇 <strong>{{ discountRange }}...</strong> 個以此類推</template>
    </div>
    <div v-else-if="item.promoType === 'discounts'"
         class="alert alert-info">
      請混搭選擇 <strong>{{ quantity }}</strong> 個商品
      <template v-if="hasDiscountRange">，<br>如需加購請選擇 <strong>{{ discountRange }}...</strong> 個以此類推</template>
    </div>
    <div v-else class="alert alert-info">
      請混搭選擇購買
      <strong>{{ quantity }}</strong> 個商品，折扣後總金額為
      <strong>{{ price }}</strong> 元
    </div>
    <order-item-menu-product-list ref="productList"
                                  :style="{ height: productListHeight }"
                                  :items="item.products"
                                  :page="page"
                                  @item-click="handleProductClick" />
    <order-item-menu-user-promo-list v-show="selectedCount > 0"
                                     :items="userPromoItems"
                                     class="user-promo-items__list"
                                     @plus="handlePlusPromoItem"
                                     @minus="handleMinusPromoItem"
                                     @remove="removeUserPromoItem" />
    <form v-show="selectedCount > 0" class="order-item-menu-promo__form form" @submit.prevent="handleSubmit">
      <promo-hint :item="item" :selected-items="userPromoItems" :cart="cart" />
      <button class="btn-primary"
              data-cy="promo-submit"
              type="submit">加到購物車</button>
    </form>
    <portal to="promo-product">
      <order-item-menu-promo-product :visible="Boolean(promoProduct)"
                                     :item="promoProduct"
                                     :max="quantity"
                                     @submit="addItemToUserPromoItems"
                                     @close="hidePromoProductMenu" />
    </portal>
  </div>
</template>

<script>
import Product from '@landing/landing-common/models/Product'
import { getApStr, toPixel } from '@landing/landing-mobile/utils'
import OrderItemMenuProductList from './OrderItemMenuProductList'
import OrderItemMenuPromoProduct from './OrderItemMenuPromoProduct'
import OrderItemMenuUserPromoList from './OrderItemMenuUserPromoList'
import PromoHint from '@landing/landing-common/components/PromoHint'
import discountText from '@landing-mobile/utils/discountText'

export default {
  name: 'OrderItemMenuPromo',
  components: {
    PromoHint,
    OrderItemMenuPromoProduct,
    OrderItemMenuUserPromoList,
    OrderItemMenuProductList
  },
  props: {
    item: Object,
    cart: Object,
    page: Object
  },
  data() {
    return {
      promoProduct: null,
      userPromoItems: [],
      swipingProductId: null,
      productListHeight: 'initial'
    }
  },
  computed: {
    quantity() {
      return this.item.promoDetails.qty
    },
    discount() {
      return discountText(this.item.promoDetails.rate)
    },
    hasDiscountRange() {
      return this.item.promoDetails.interval > 0
    },
    discountRange() {
      const { qty, interval } = this.item.promoDetails
      return getApStr({ qty, interval })
    },
    price() {
      return this.item.promoDetails.price
    },
    selectedCount() {
      return this.userPromoItems.reduce((total, row) => row.qty + total, 0)
    },
    restCount() {
      return this.quantity - this.selectedCount
    }
  },
  mounted() {
    this.setProductListHeight()
  },
  methods: {
    hidePromoProductMenu() {
      this.promoProduct = null
    },
    setProductListHeight() {
      const top = this.$refs.productList.$el.offsetTop
      this.productListHeight = toPixel(window.innerHeight - top)
    },
    handleProductClick(product) {
      this.promoProduct = new Product(product)
    },
    removeUserPromoItem(item) {
      this.userPromoItems = this.userPromoItems.filter(row => row.uuid !== item.uuid)
    },
    handlePlusPromoItem(item) {
      const isDiscountType = (this.item.promoType === 'discounts')
      this.userPromoItems = this.userPromoItems.map(row => {
        const sameId = (row.uuid === item.uuid)
        if (isDiscountType && sameId) {
          return Object.assign({}, row, { qty: row.qty + 1 })
        }
        if (sameId && (row.qty < this.quantity)) {
          return Object.assign({}, row, { qty: row.qty + 1 })
        }
        return row
      })
    },
    handleMinusPromoItem(item) {
      this.userPromoItems = this.userPromoItems.map(row => {
        if ((row.uuid === item.uuid) && (row.qty > 1)) {
          return Object.assign({}, row, { qty: row.qty - 1 })
        }
        return row
      })
    },
    handleSubmit() {
      const { id, title, image, promoDetails } = this.item
      this.$emit('submit', {
        id,
        type: 'promo',
        title,
        image,
        qty: 1,
        price: promoDetails.price,
        products: this.userPromoItems
      })
    },
    addItemToUserPromoItems(item) {

      const existedItem = this.userPromoItems.find(row => {
        const sameProduct = (row.id === item.id)
        const sameSpec = (row.specId === item.specId)
        return sameProduct && sameSpec
      })
      if (existedItem) {
        existedItem.qty += item.qty
      }
      else {
        const productIndices = this.item.products.reduce((obj, row, index) => {
          obj[row.id] = index
          return obj
        }, {})
        this.userPromoItems.push(item)
        // sort items by given product order
        this.userPromoItems.sort((a, b) => {
          if (a.id === b.id) {
            return a.specId - b.specId
          }
          return productIndices[a.id] - productIndices[b.id]
        })
      }
      this.hidePromoProductMenu()
    }
  }
}
</script>

<style lang="scss" scoped>
.order-item-menu-promo {
  padding: 0 14px 14px;
}
.order-item-menu-promo__form {
  border-top: 1px solid $gray3;
  background-color: $white1;
  padding: 14px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  .btn-primary {
    margin-left: .5em;
    white-space: nowrap;
  }
}
.alert-info {
  margin-bottom: 18px;
}
.user-promo-items__list {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
