<template>
  <app-backdrop :is-visible="isVisible" @click="$emit('backdrop-click')">
    <transition name="vertical-slide" appear>
      <div v-if="isVisible" ref="menu"
           class="vertical-menu" :class="{ 'scrollable': scrollable }">
        <div class="vertical-menu__heading-bar">
          <div class="vertical-menu__heading">
            <slot name="label" />
            {{ heading }}
          </div>
          <close-btn v-if="showCloseBtn"
                     class="vertical-menu__close-btn"
                     @click="$emit('close')" />
        </div>
        <slot />
      </div>
    </transition>
  </app-backdrop>
</template>

<script>
import getScrollMixin from '@landing/common/mixins/bodyScrollMixin'
import AppBackdrop from './AppBackdrop'
import CloseBtn from './CloseBtn'

const bodyScrollMixin  = getScrollMixin('isVisible')

export default {
  name: 'VerticalMenu',
  components: {
    AppBackdrop,
    CloseBtn
  },
  mixins: [bodyScrollMixin],
  props: {
    isVisible: Boolean,
    heading: String,
    showCloseBtn: {
      type: Boolean,
      default: true
    },
    scrollable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleTouchMove(event) {
      event.stopPropagation()
    }
  }
}
</script>

<style lang="scss" scoped>
.vertical-menu {
  max-height: 100%;
  overflow: hidden;
  z-index: 1;
  background-color: $white1;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  &.scrollable {
    overflow: auto;
  }
}
.vertical-menu__heading-bar {
  position: relative;
  display: flex;
  align-items: center;
}
.vertical-menu__heading {
  color: $black0;
  font-weight: 400;
  padding: 14px 40px 14px 14px;
  font-size: 1.2em;
}
.vertical-menu__close-btn {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
}
.vertical-menu__back-btn {
  padding: 0;
  @include size(50px);
}
</style>
