import Vue from '../consts/vue'
import mapDispatch from '../redux/mapDispatch'
import { addPrefix, get, isClient, isServer } from '../utils'

Vue.mixin({
  beforeCreate() {
    this.mapDispatch = mapDispatch
    this.mapStateToMixin = (obj, prefix) => this.mapState(addPrefix(obj, prefix))
    this.mapDispatchToMixin = (obj, prefix) => this.mapDispatch(addPrefix(obj, prefix))
    this.isServer = isServer
    this.isClient = isClient
    this.get = get
    if (this.$route) {
      this.query = this.$route.query
    }
  }
})
