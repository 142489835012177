import { addToastItem, removeToastItem, setCenterToast } from '../redux/reducers/toast'

export default {
  data() {
    return this.mapState({ toast: 'toast' })
  },
  beforeCreate() {
    this.mapDispatch({ addToastItem, removeToastItem, setCenterToast })
  }
}
