import { hideAlert, showAlert } from '../redux/reducers/alert'

export default {
  data() {
    return this.mapState({ alert: 'alert' })
  },
  beforeCreate() {
    this.mapDispatch({ hideAlert, showAlert })
  }
}
