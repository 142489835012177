import { NEWEST_FIRST } from '@landing/landing-common/consts/sortMethods'

export const SHOW_FREE_PRODUCT_SET_MENU = '@landing-mobile/app/SHOW_FREE_PRODUCT_SET_MENU'
export const HIDE_FREE_PRODUCT_SET_MENU = '@landing-mobile/app/HIDE_FREE_PRODUCT_SET_MENU'

export const SHOW_SORT_MENU = '@landing-mobile/app/SHOW_SORT_MENU'
export const HIDE_SORT_MENU = '@landing-mobile/app/HIDE_SORT_MENU'

export const SHOW_ORDER_ITEM_MENU = '@landing-mobile/app/SHOW_ORDER_ITEM_MENU'
export const HIDE_ORDER_ITEM_MENU = '@landing-mobile/app/HIDE_ORDER_ITEM_MENU'

export const SHOW_NAVBAR_MENU = '@landing-mobile/app/SHOW_NAVBAR_MENU'
export const HIDE_NAVBAR_MENU = '@landing-mobile/app/HIDE_NAVBAR_MENU'

export const SET_SORT_METHOD = '@landing-mobile/app/SET_SORT_METHOD'

export const SHOW_CART_MODAL = '@landing-mobile/app/SHOW_CART_MODAL'
export const HIDE_CART_MODAL = '@landing-mobile/app/HIDE_CART_MODAL'

export const SET_ONLINE_STATUS = '@landing-mobile/app/SET_ONLINE_STATUS'

export const SET_SITE_NAV_HEIGHT = '@landing-mobile/app/SET_SITE_NAV_HEIGHT'

export const SET_TRACKER_LOADED = '@landing-mobile/app/SET_TRACKER_LOADED'

export const setTrackerLoaded = loaded => {
  return {
    type: SET_TRACKER_LOADED,
    loaded
  }
}

export const setSiteNavHeight = height => {
  return {
    type: SET_SITE_NAV_HEIGHT,
    height
  }
}

export const setOnlineStatus = online => {
  return {
    type: SET_ONLINE_STATUS,
    online
  }
}

export const showFreeProductSetMenu = () => {
  return {
    type: SHOW_FREE_PRODUCT_SET_MENU
  }
}

export const hideFreeProductSetMenu = () => {
  return {
    type: HIDE_FREE_PRODUCT_SET_MENU
  }
}

export const setSortMethod = method => {
  return {
    type: SET_SORT_METHOD,
    method
  }
}

export const showSortMenu = () => {
  return {
    type: SHOW_SORT_MENU
  }
}

export const hideSortMenu = () => {
  return {
    type: HIDE_SORT_MENU
  }
}

export const showOrderItemMenu = item => {
  return {
    type: SHOW_ORDER_ITEM_MENU,
    item
  }
}

export const hideOrderItemMenu = () => {
  return {
    type: HIDE_ORDER_ITEM_MENU
  }
}

export const showNavbarMenu = () => {
  return {
    type: SHOW_NAVBAR_MENU
  }
}

export const hideNavbarMenu = () => {
  return {
    type: HIDE_NAVBAR_MENU
  }
}

export const showCartModal = () => {
  return {
    type: SHOW_CART_MODAL
  }
}

export const hideCartModal = () => {
  return {
    type: HIDE_CART_MODAL
  }
}

const initialState = {
  siteNavHeight: 0,
  online: true,
  isFreeProductSetMenuVisible: false,
  isSortMenuVisible: false,
  isNavbarMenuVisible: false,
  isOrderItemMenuVisible: false,
  isCartModalVisible: false,
  selectedOrderItem: null,
  sortMethod: NEWEST_FIRST,
  trackerLoaded: false
}

export default function app(state = initialState, action) {

  switch (action.type) {
    case SET_TRACKER_LOADED:
      return { ...state, trackerLoaded: action.loaded }
    case SET_SITE_NAV_HEIGHT: {
      // https://github.com/superlanding/landing/issues/1285
      // mobile safari updated hook triggers 0 offsetHeight
      if (action.height > 0) {
        return { ...state, siteNavHeight: action.height }
      }
      return state
    }
    case SET_ONLINE_STATUS:
      return { ...state, online: action.online }
    case SHOW_FREE_PRODUCT_SET_MENU:
      return { ...state, isFreeProductSetMenuVisible: true }
    case HIDE_FREE_PRODUCT_SET_MENU:
      return { ...state, isFreeProductSetMenuVisible: false }
    case SHOW_SORT_MENU:
      return { ...state, isSortMenuVisible: true }
    case HIDE_SORT_MENU:
      return { ...state, isSortMenuVisible: false }
    case SHOW_ORDER_ITEM_MENU:
      return {
        ...state,
        isOrderItemMenuVisible: true,
        selectedOrderItem: action.item
      }
    case HIDE_ORDER_ITEM_MENU:
      return { ...state, isOrderItemMenuVisible: false }
    case SHOW_NAVBAR_MENU:
      return { ...state, isNavbarMenuVisible: true }
    case HIDE_NAVBAR_MENU:
      return { ...state, isNavbarMenuVisible: false }
    case SET_SORT_METHOD:
      return { ...state, sortMethod: action.method }
    case SHOW_CART_MODAL:
      return { ...state, isCartModalVisible: true }
    case HIDE_CART_MODAL:
      return { ...state, isCartModalVisible: false }
    default:
      return state
  }
}
