<template>
  <button class="btn-icon close-btn"
          data-cy="close-btn"
          @click.stop.prevent="$emit('click')">
    <fa-icon icon="times" />
  </button>
</template>

<script>
export default {
  name: 'CloseBtn'
}
</script>

<style lang="scss" scoped>
.close-btn {
  display: block;
  color: $black7;
  @include size(50px);
  padding: 0;
}
</style>
