import createEpic from '../createEpic'
import { ADD_TOAST_ITEM, REMOVE_TOAST_ITEM } from '../reducers/toast'
import { map, delay } from '@landing/landing-mobile/utils'

const closeToastAfterDelay = () => [
  ADD_TOAST_ITEM,
  map(action => {
    return { type: REMOVE_TOAST_ITEM, id: action.data.id }
  }),
  delay(3 * 1000)
]

export default [
  createEpic(closeToastAfterDelay)
]
