<template>
  <app-backdrop class="app-backdrop" :is-visible="visible" @click="$emit('close')">
    <div class="transition-wrapper" @click="$emit('close')">
      <transition name="pop" appear>
        <div v-if="visible" class="order-item-menu-promo-product scrollable" @click.stop>
          <div class="order-item-menu-promo-product__title">{{ item.title }}</div>
          <app-image v-if="item.hasImg"
                     class="order-item-menu-promo-product__img"
                     :src="productImgSrc"
                     :alt="item.title" />
          <preorder-info v-if="item.isPreorder"
                         class="order-item-menu-promo-product__preorder-info"
                         :item="item"
                         mode="list" />
          <product-desc-links class="order-item-menu-promo-product__product-desc-links"
                              :item="item"
                              :active="activeProductKey"
                              @click="setProductImgRow" />
          <form class="order-item-menu-promo-product__form form"
                @submit.stop.prevent="handleSubmit">
            <label v-if="item.hasSpec">
              <span>選擇規格</span>
              <select v-model="selectedSpecId" class="select" data-cy="spec-select">
                <option v-for="o in item.specOptions"
                        :key="o.value"
                        :value="o.value">{{ o.text }}</option>
              </select>
            </label>
            <label>
              <span>選擇數量</span>
              <select v-model="selectedQuantity" class="select" data-cy="qty-select">
                <option v-for="o in item.getQuantityOptionsByNum(max)"
                        :key="o.value"
                        :value="o.value">{{ o.text }}</option>
              </select>
            </label>
            <button class="btn-primary fw" type="submit">新增到組合</button>
          </form>
          <close-btn class="order-item-menu-promo-product__close-btn"
                     @click="$emit('close')" />
        </div>
      </transition>
    </div>
  </app-backdrop>
</template>

<script>
import alertMixin from '@landing/landing-mobile/mixins/alertMixin'
import { uuid } from '@landing/landing-mobile/utils'
import AppBackdrop from './AppBackdrop'
import AppImage from './AppImage'
import CloseBtn from './CloseBtn'
import PreorderInfo from '@landing/landing-common/components/PreorderInfo'
import ProductDescLinks from '@landing-common/components/ProductDescLinks'
import productImgMixin from '@landing-common/mixins/productImgMixin'

export default {
  name: 'OrderItemMenuPromoProduct',
  components: {
    AppImage,
    AppBackdrop,
    CloseBtn,
    PreorderInfo,
    ProductDescLinks
  },
  mixins: [alertMixin, productImgMixin],
  props: {
    visible: Boolean,
    item: Object,
    max: Number
  },
  data() {
    return {
      selectedSpecId: null,
      selectedQuantity: null
    }
  },
  watch: {
    item(value) {
      if (value) {
        const { defaultSelectedSpecId, defaultSelectedQuantity } = value
        this.selectedSpecId = defaultSelectedSpecId
        this.selectedQuantity = defaultSelectedQuantity
      }
    }
  },
  methods: {
    hasOutOfStockSpec(item, specId) {
      const specOption = item.specOptions.find(o => o.value === specId)
      if (specOption) {
        return (specOption.status === 'out_of_stock')
      }
      return false
    },
    handleSubmit() {
      const { item } = this
      if (this.hasOutOfStockSpec(item, this.selectedSpecId)) {
        return this.showAlert({ message: '此規格缺貨中' })
      }
      const data = {
        id: item.id,
        title: item.title,
        unitName: item.unitName,
        specId: this.selectedSpecId,
        qty: this.selectedQuantity,
        price: item.price,
        uuid: uuid()
      }
      if (item.hasSpec) {
        data.spec = item.specs.find(row => row.id === this.selectedSpecId).title
      }
      if (item.image) {
        data.image = item.image
      }
      this.$emit('submit', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-backdrop {
  @include flex-center;
}
.transition-wrapper {
  padding: 14px;
}
.order-item-menu-promo-product {
  max-height: 100vh;
  position: relative;
  background-color: $white1;
  padding: 14px;
}
.order-item-menu-promo-product__title {
  color: $black0;
  font-weight: 400;
  padding: 0 34px 14px 0;
  font-size: 1.2em;
}
.order-item-menu-promo-product__img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 40vh;
  height: auto;
}
.order-item-menu-promo-product__form {
  label {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}
.btn-primary {
  margin-top: 18px;
}
.order-item-menu-promo-product__close-btn {
  position: absolute;
  right: 0;
  top: 0;
}
.order-item-menu-promo-product__preorder-info {
  margin-top: 1em;
  margin-bottom: .2em;
}
.order-item-menu-promo-product__product-desc-links {
  margin-top: 1em;
}
</style>
