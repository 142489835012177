<template>
  <div class="promo-list-wrapper" :class="{ opened }" :style="{ height }">
    <div class="promo-list-wrapper__content">
      <button class="btn-icon arrow-btn" @click.stop.prevent="handleArrowBtnClick">
        <div class="arrow-icon" />
      </button>
      <transition-group name="fade"
                        class="promo-list scrollable"
                        tag="ul"
                        appear>
        <order-item-menu-user-promo-list-item v-for="item in items"
                                              :ref="item.uuid"
                                              :key="item.uuid"
                                              :item="item"
                                              @plus="handlePlus"
                                              @minus="handleMinus"
                                              @remove="handleRemove" />
        <li key="placeholder"
            class="placeholder"
            :style="{ height: placeholderHeight }" />
      </transition-group>
    </div>
  </div>
</template>

<script>
import { toPixel } from '@landing/landing-mobile/utils'
import OrderItemMenuUserPromoListItem from './OrderItemMenuUserPromoListItem'

const HEIGHT_BOTTOM_BAR = 82

export default {
  name: 'OrderItemMenuUserPromoList',
  components: {
    OrderItemMenuUserPromoListItem
  },
  props: {
    items: Array
  },
  data() {
    return {
      opened: true,
      menuHeight: 'initial'
    }
  },
  computed: {
    overLimit() {
      return (this.items.length > 5)
    },
    height() {
      let menuHeight = this.menuHeight
      if (menuHeight !== 'initial') {
        menuHeight = toPixel(this.menuHeight + HEIGHT_BOTTOM_BAR)
      }
      const openedHeight = this.overLimit ? '50%' : menuHeight
      return this.opened ? openedHeight : toPixel(HEIGHT_BOTTOM_BAR - 10)
    },
    placeholderHeight() {
      return this.overLimit ? toPixel(HEIGHT_BOTTOM_BAR) : '0'
    }
  },
  updated() {
    this.setMenuHeight()
  },
  methods: {
    setMenuHeight() {
      this.menuHeight = this.items.map(item => item.uuid)
        .reduce((height, uuid) => {
          return this.$refs[uuid][0].$el.offsetHeight + height
        }, 0)
    },
    handleRemove(item) {
      this.$emit('remove', item)
    },
    handlePlus(item) {
      this.$emit('plus', item)
    },
    handleMinus(item) {
      this.$emit('minus', item)
    },
    handleArrowBtnClick() {
      this.opened = (! this.opened)
    }
  }
}
</script>

<style lang="scss" scoped>
.promo-list-wrapper {
  transition: all .3s;
  &.opened .arrow-icon {
    transform: rotate(0);
    margin-bottom: -30px;
  }
}
.promo-list-wrapper__content {
  height: 100%;
  position: relative;
}
.promo-list {
  background-color: $gray2;
  height: 100%;
  overflow-y: auto;
  color: $black5;
  padding-right: 14px;
}
.arrow-btn {
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  display: block;
  @include size(50px);
  margin-left: auto;
  margin-right: auto;
  outline: 0;
}
.arrow-icon {
  transition: all .3s;
  transform: rotate(-180deg);
  $size: 14px;
  margin: auto;
  @include size($size);
  border-top: $size solid $gray1;
  border-right: $size solid transparent;
  border-left: $size solid transparent;
  border-bottom: $size solid transparent;
}
</style>
