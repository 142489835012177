<template>
  <div class="sales-page" :class="containerClass" :style="{ paddingTop: paddingTop }">
    <app-nav v-if="hasSiteNav || hasNavbar"
             :site-nav-visible="hasSiteNav"
             :navbar-visible="hasNavbar"
             :page="page"
             :mounted="setNavHeight"
             :updated="setNavHeight"
             @toggle-btn-click="showNavbarMenu" />
    <editor-content :page="page" @scroll-to-product-list="scrollToProductList" />

    <discount-lv-list v-if="page.discountLevels.length > 0"
                      ref="discountLvList"
                      class="sales-page__discount-lv-list"
                      :items="page.discountLevels"
                      :layout-discount="$consts.layoutDiscount"
                      :total-order-amount-with-coupon="cartData.totalOrderAmountWithCoupon" />

    <product-list ref="productList"
                  class="app__product-list"
                  :products="page.products"
                  :addtional-products="page.addtionalProducts"
                  :promos="page.promos"
                  :mode="page.layout.products"
                  :priority="page.layout.priority"
                  :product-banner="page.productBanner.mobile"
                  :addtional-product-banner="page.addtionalProductBanner.mobile" />
    <fixed-toolbar :page="page"
                   :count-in-cart="cartItems.length"
                   :is-loading-cart="isLoadingCart"
                   :tooltip-message="tooltipMessage"
                   @click-cart="handleCartClick" />
    <app-footer :page="page" />
    <div v-if="applicableDiscountItem && discountHintVisible"
         class="sales-page__discount-lv-title"
         :style="{ top: paddingTop }">
      <discount-lv-title :item="applicableDiscountItem"
                         :layout-discount="$consts.layoutDiscount"
                         :short-by="applicableDiscountItem.amount - cartData.totalOrderAmountWithCoupon" />
    </div>
    <cart-modal :is-visible="isCartModalVisible"
                @reload="reloadCart"
                @close="hideCartModal" />
    <checkout-bar :is-visible="cartItems.length > 0"
                  :total="cartData.totalOrderAmountWithDiscount"
                  :free-shipping-balance="cartData.freeShippingBalance"
                  :btn-text="checkoutBarBtnText"
                  @checkout="handleCheckout" />
    <sort-menu :is-visible="isSortMenuVisible"
               :selected-method="sortMethod"
               :method-rows="$options.SORT_METHOD_ROWS"
               @checkmark-btn-click="handleCheckmarkBtnClick"
               @close="hideSortMenu" />
    <order-item-menu :is-visible="isOrderItemMenuVisible"
                     :item="selectedOrderItem"
                     @submit="handleOrderItemMenuSubmit"
                     @close="hideOrderItemMenu" />
    <free-product-set-menu :is-visible="isFreeProductSetMenuVisible"
                           :item="freeProductSet"
                           @submit="handleFreeProductSetMenuSubmit" />
    <navbar-menu v-if="hasSiteNav"
                 :items="page.site.navbar.items"
                 :is-visible="isNavbarMenuVisible"
                 @close-btn-click="hideNavbarMenu"
                 @router-push="hideNavbarMenu" />
    <portal-target name="promo-product" />
    <app-alert :is-visible="alert.isVisible"
               :data="alert.data"
               @close="hideAlert" />
    <app-confirm :is-visible="confirm.isVisible"
                 :data="confirm.data"
                 @close="hideConfirm" />
    <app-toast :items="toast.items"
               @remove-toast-item="removeToastItem" />
    <center-toast :text="toast.centerToastText" />
  </div>
</template>

<script>
import CartModal from '@landing/landing-mobile/containers/CartModal'
import OrderItemMenu from '@landing/landing-mobile/containers/OrderItemMenu'
import ProductList from '@landing/landing-mobile/containers/ProductList'
import AppAlert from '@landing/landing-mobile/components/AppAlert'
import AppConfirm from '@landing/landing-mobile/components/AppConfirm'
import AppFooter from '@landing/landing-mobile/components/AppFooter'
import AppNav from '@landing/landing-mobile/components/AppNav'
import AppToast from '@landing/landing-mobile/components/AppToast'
import CenterToast from '@landing/landing-mobile/components/CenterToast'
import CheckoutBar from '@landing/landing-mobile/components/CheckoutBar'
import DiscountLvList from '@landing-common/components/DiscountLvList'
import DiscountLvTitle from '@common/components/DiscountLvTitle'
import EditorContent from '@landing/landing-mobile/components/EditorContent'
import FixedToolbar from '@landing/landing-mobile/components/FixedToolbar'
import FreeProductSetMenu from '@landing/landing-mobile/components/FreeProductSetMenu'
import NavbarMenu from '@landing/landing-mobile/components/NavbarMenu'
import SortMenu from '@landing/landing-mobile/components/SortMenu'
import alertMixin from '@landing/landing-mobile/mixins/alertMixin'
import appNavMixin from '@landing/landing-mobile/mixins/appNavMixin'
import cartMixin from '@landing/landing-mobile/mixins/cartMixin'
import confirmMixin from '@landing/landing-mobile/mixins/confirmMixin'
import domMixin from '@landing/common/mixins/domMixin'
import discountHintMixin from '@landing-mobile/mixins/discountHintMixin'
import productListMixin from '@landing/landing-mobile/mixins/productListMixin'
import toastMixin from '@landing/landing-mobile/mixins/toastMixin'
import { setTrackerLoaded } from '@landing/landing-mobile/redux/reducers/app'

export default {
  name: 'SalesPage',
  components: {
    AppAlert,
    AppConfirm,
    AppFooter,
    AppNav,
    AppToast,
    CartModal,
    CenterToast,
    CheckoutBar,
    DiscountLvList,
    DiscountLvTitle,
    EditorContent,
    FixedToolbar,
    FreeProductSetMenu,
    NavbarMenu,
    OrderItemMenu,
    ProductList,
    SortMenu
  },
  mixins: [
    domMixin,
    discountHintMixin,
    alertMixin,
    confirmMixin,
    appNavMixin,
    toastMixin,
    productListMixin,
    cartMixin
  ],
  inheritAttrs: false,
  computed: {
    containerClass() {
      return {
        'sales-page--has-app-nav': this.hasSiteNav,
        'sales-page--has-navbar': this.hasNavbar
      }
    }
  },
  beforeCreate() {
    this.mapDispatch({ setTrackerLoaded })
    if (this.isClient) {
      this.$tracker.enable()
      this.cartDataReady = () => {
        this.$tracker.setCart(this.cart)
        this.setTrackerLoaded(true)
        this.$tracker.pageView({ step: 1 })
      }
    }
  }
}
</script>

<style lang="scss">
.sales-page__discount-lv-list .discount-lv-title__tag {
  display: block;
  margin-top: .2rem;
  margin-left: 0;
  width: fit-content;
}
</style>

<style lang="scss" scoped>
.sales-page {
  padding-bottom: 140px;
  &.sales-page--has-app-nav {
    padding-top: 53px;
  }
  &.sales-page--has-navbar {
    padding-top: 34px;
  }
  &.sales-page--has-app-nav.sales-page--has-navbar {
    padding-top: 87px;
  }
}
.sales-page__discount-lv-list {
  display: block;
  margin: 1.2rem 14px 1rem;
  li {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }
}
.sales-page__discount-lv-title {
  font-size: .8rem;
  background: #f4f4f4;
  padding: .5rem;
  line-height: 2;
  position: fixed;
  left: 0;
  right: 0;
}
</style>
